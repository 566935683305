// c, u, s
// github ca57733

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCitizenship = /* GraphQL */ `
  mutation CreateCitizenship($create: CreateCitizenshipInput!) {
    result: createCitizenship(create: $create) {
      id
      name
      deleted
    }
  }
`;
export const updateCitizenship = /* GraphQL */ `
  mutation UpdateCitizenship($update: UpdateCitizenshipInput!) {
    result: updateCitizenship(update: $update) {
      id
      name
      deleted
    }
  }
`;
export const createIncidentType = /* GraphQL */ `
  mutation CreateIncidentType($create: CreateIncidentTypeInput!) {
    result: createIncidentType(create: $create) {
      id
      name
      deleted
    }
  }
`;
export const updateIncidentType = /* GraphQL */ `
  mutation UpdateIncidentType($update: UpdateIncidentTypeInput!) {
    result: updateIncidentType(update: $update) {
      id
      name
      deleted
    }
  }
`;
export const createNationality = /* GraphQL */ `
  mutation CreateNationality($create: CreateNationalityInput!) {
    result: createNationality(create: $create) {
      country
      name
      nationality
      alpha2
      alpha3
      numeric
      subdivision
      independent
      order
      deleted
    }
  }
`;
export const updateNationality = /* GraphQL */ `
  mutation UpdateNationality($update: UpdateNationalityInput!) {
    result: updateNationality(update: $update) {
      country
      name
      nationality
      alpha2
      alpha3
      numeric
      subdivision
      independent
      order
      deleted
    }
  }
`;
export const createManagementAgency = /* GraphQL */ `
  mutation CreateManagementAgency($create: CreateManagementAgencyInput!) {
    result: createManagementAgency(create: $create) {
      id
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateManagementAgency = /* GraphQL */ `
  mutation UpdateManagementAgency($update: UpdateManagementAgencyInput!) {
    result: updateManagementAgency(update: $update) {
      id
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPLRDGrade = /* GraphQL */ `
  mutation CreatePLRDGrade($create: CreatePLRDGradeInput!) {
    result: createPLRDGrade(create: $create) {
      id
      name
      code
      prerequisite
      yearsOfExperience
      mandatoryReq
      optionalReq
      deleted
    }
  }
`;
export const updatePLRDGrade = /* GraphQL */ `
  mutation UpdatePLRDGrade($update: UpdatePLRDGradeInput!) {
    result: updatePLRDGrade(update: $update) {
      id
      name
      code
      prerequisite
      yearsOfExperience
      mandatoryReq
      optionalReq
      deleted
    }
  }
`;
export const createPWMTrainingModule = /* GraphQL */ `
  mutation CreatePWMTrainingModule($create: CreatePWMTrainingModuleInput!) {
    result: createPWMTrainingModule(create: $create) {
      id
      uid
      PLRDGradeID
      trainingModuleID
      mandatory
      from
      to
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePWMTrainingModule = /* GraphQL */ `
  mutation UpdatePWMTrainingModule($update: UpdatePWMTrainingModuleInput!) {
    result: updatePWMTrainingModule(update: $update) {
      id
      uid
      PLRDGradeID
      trainingModuleID
      mandatory
      from
      to
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createRace = /* GraphQL */ `
  mutation CreateRace($create: CreateRaceInput!) {
    result: createRace(create: $create) {
      id
      name
      deleted
    }
  }
`;
export const updateRace = /* GraphQL */ `
  mutation UpdateRace($update: UpdateRaceInput!) {
    result: updateRace(update: $update) {
      id
      name
      deleted
    }
  }
`;
export const createReligion = /* GraphQL */ `
  mutation CreateReligion($create: CreateReligionInput!) {
    result: createReligion(create: $create) {
      id
      name
      deleted
    }
  }
`;
export const updateReligion = /* GraphQL */ `
  mutation UpdateReligion($update: UpdateReligionInput!) {
    result: updateReligion(update: $update) {
      id
      name
      deleted
    }
  }
`;
export const createTrainingModule = /* GraphQL */ `
  mutation CreateTrainingModule($create: CreateTrainingModuleInput!) {
    result: createTrainingModule(create: $create) {
      id
      uid
      code
      code2
      module
      level
      title
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateTrainingModule = /* GraphQL */ `
  mutation UpdateTrainingModule($update: UpdateTrainingModuleInput!) {
    result: updateTrainingModule(update: $update) {
      id
      uid
      code
      code2
      module
      level
      title
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance($create: CreateAttendanceInput!, $agencyID: Int) {
    result: createAttendance(create: $create, agencyID: $agencyID) {
      id
      accountID
      workHistoryID
      securityAgencyID
      staffProfileID
      staffName
      staffNo
      timeIn
      timeInSiteID
      timeInSiteProfileID
      timeInKey
      timeInBucket
      timeInRegion
      timeInLatitude
      timeInLongitude
      timeInRemark
      timeInSiteName
      timeInSiteProfileName
      timeOut
      timeOutSiteID
      timeOutSiteProfileID
      timeOutKey
      timeOutBucket
      timeOutRegion
      timeOutLatitude
      timeOutLongitude
      timeOutRemark
      timeOutSiteName
      timeOutSiteProfileName
      approvalStatus
      approvedDuration
      reviewedBy
      reviewedTimestamp
      remark
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance($update: UpdateAttendanceInput!, $agencyID: Int) {
    result: updateAttendance(update: $update, agencyID: $agencyID) {
      id
      accountID
      workHistoryID
      securityAgencyID
      staffProfileID
      staffName
      staffNo
      timeIn
      timeInSiteID
      timeInSiteProfileID
      timeInKey
      timeInBucket
      timeInRegion
      timeInLatitude
      timeInLongitude
      timeInRemark
      timeInSiteName
      timeInSiteProfileName
      timeOut
      timeOutSiteID
      timeOutSiteProfileID
      timeOutKey
      timeOutBucket
      timeOutRegion
      timeOutLatitude
      timeOutLongitude
      timeOutRemark
      timeOutSiteName
      timeOutSiteProfileName
      approvalStatus
      approvedDuration
      reviewedBy
      reviewedTimestamp
      remark
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClientProfile = /* GraphQL */ `
  mutation CreateClientProfile(
    $create: CreateClientProfileInput!
    $agencyID: Int
  ) {
    result: createClientProfile(create: $create, agencyID: $agencyID) {
      id
      securityAgencyID
      managementAgencyID
      clientAgencyID
      permission
      status
      name
      phoneNo
      email
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClientProfile = /* GraphQL */ `
  mutation UpdateClientProfile(
    $update: UpdateClientProfileInput!
    $agencyID: Int
  ) {
    result: updateClientProfile(update: $update, agencyID: $agencyID) {
      id
      securityAgencyID
      managementAgencyID
      clientAgencyID
      permission
      status
      name
      phoneNo
      email
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClientUserProfile = /* GraphQL */ `
  mutation CreateClientUserProfile(
    $create: CreateClientUserProfileInput!
    $agencyID: Int
  ) {
    result: createClientUserProfile(create: $create, agencyID: $agencyID) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      clientAgencyID
      clientProfileID
      name
      phoneNo
      email
      permission
      status
      start
      end
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClientUserProfile = /* GraphQL */ `
  mutation UpdateClientUserProfile(
    $update: UpdateClientUserProfileInput!
    $agencyID: Int
  ) {
    result: updateClientUserProfile(update: $update, agencyID: $agencyID) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      clientAgencyID
      clientProfileID
      name
      phoneNo
      email
      permission
      status
      start
      end
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClockingPoint = /* GraphQL */ `
  mutation CreateClockingPoint(
    $create: CreateClockingPointInput!
    $agencyID: Int
  ) {
    result: createClockingPoint(create: $create, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      description
      tag
      isAttendance
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClockingPoint = /* GraphQL */ `
  mutation UpdateClockingPoint(
    $update: UpdateClockingPointInput!
    $agencyID: Int
  ) {
    result: updateClockingPoint(update: $update, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      description
      tag
      isAttendance
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClockingRoute = /* GraphQL */ `
  mutation CreateClockingRoute(
    $create: CreateClockingRouteInput!
    $agencyID: Int
  ) {
    result: createClockingRoute(create: $create, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      routeDetails
      inOrder
      islandWide
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClockingRoute = /* GraphQL */ `
  mutation UpdateClockingRoute(
    $update: UpdateClockingRouteInput!
    $agencyID: Int
  ) {
    result: updateClockingRoute(update: $update, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      routeDetails
      inOrder
      islandWide
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClockingReport = /* GraphQL */ `
  mutation CreateClockingReport(
    $create: CreateClockingReportInput!
    $agencyID: Int
  ) {
    result: createClockingReport(create: $create, agencyID: $agencyID) {
      id
      securitySiteID
      securityAgencyID
      siteProfileID
      clockingRouteID
      accountID
      staffProfileID
      status
      remark
      start
      end
      totalPoint
      clockedPoint
      islandWide
      securitySiteName
      securityAgencyName
      siteProfileName
      clockingRouteName
      staffNo
      staffProfileName
      staffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClockingReport = /* GraphQL */ `
  mutation UpdateClockingReport(
    $update: UpdateClockingReportInput!
    $agencyID: Int
  ) {
    result: updateClockingReport(update: $update, agencyID: $agencyID) {
      id
      securitySiteID
      securityAgencyID
      siteProfileID
      clockingRouteID
      accountID
      staffProfileID
      status
      remark
      start
      end
      totalPoint
      clockedPoint
      islandWide
      securitySiteName
      securityAgencyName
      siteProfileName
      clockingRouteName
      staffNo
      staffProfileName
      staffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createClockingReportDetail = /* GraphQL */ `
  mutation CreateClockingReportDetail(
    $create: CreateClockingReportDetailInput!
    $agencyID: Int
  ) {
    result: createClockingReportDetail(create: $create, agencyID: $agencyID) {
      clockingReportID
      clockingPointID
      tag
      datetime
      remark
      status
      securityAgencyID
      clockingPointName
      siteProfileID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateClockingReportDetail = /* GraphQL */ `
  mutation UpdateClockingReportDetail(
    $update: UpdateClockingReportDetailInput!
    $agencyID: Int
  ) {
    result: updateClockingReportDetail(update: $update, agencyID: $agencyID) {
      clockingReportID
      clockingPointID
      tag
      datetime
      remark
      status
      securityAgencyID
      clockingPointName
      siteProfileID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract($create: CreateContractInput!) {
    result: createContract(create: $create) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      startDatetime
      endDatetime
      status
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract($update: UpdateContractInput!) {
    result: updateContract(update: $update) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      startDatetime
      endDatetime
      status
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createContractFile = /* GraphQL */ `
  mutation CreateContractFile($create: CreateContractFileInput!) {
    result: createContractFile(create: $create) {
      id
      contractID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateContractFile = /* GraphQL */ `
  mutation UpdateContractFile($update: UpdateContractFileInput!) {
    result: updateContractFile(update: $update) {
      id
      contractID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createContractIndicator = /* GraphQL */ `
  mutation CreateContractIndicator($create: CreateContractIndicatorInput!) {
    result: createContractIndicator(create: $create) {
      id
      contractID
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateContractIndicator = /* GraphQL */ `
  mutation UpdateContractIndicator($update: UpdateContractIndicatorInput!) {
    result: updateContractIndicator(update: $update) {
      id
      contractID
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createContractMeeting = /* GraphQL */ `
  mutation CreateContractMeeting($create: CreateContractMeetingInput!) {
    result: createContractMeeting(create: $create) {
      id
      contractID
      startDatetime
      endDatetime
      type
      venue
      note
      actionPlan
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateContractMeeting = /* GraphQL */ `
  mutation UpdateContractMeeting($update: UpdateContractMeetingInput!) {
    result: updateContractMeeting(update: $update) {
      id
      contractID
      startDatetime
      endDatetime
      type
      venue
      note
      actionPlan
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDeployment = /* GraphQL */ `
  mutation CreateDeployment($create: CreateDeploymentInput!, $agencyID: Int) {
    result: createDeployment(create: $create, agencyID: $agencyID) {
      id
      id2
      date
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      workHistoryID
      startDatetime
      endDatetime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMultipleDeployment = /* GraphQL */ `
  mutation CreateMultipleDeployment(
    $create: [CreateMultipleDeploymentInput]!
    $agencyID: Int
  ) {
    result: createMultipleDeployment(create: $create, agencyID: $agencyID) {
      id
      id2
      date
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      workHistoryID
      startDatetime
      endDatetime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDeployment = /* GraphQL */ `
  mutation UpdateDeployment($update: UpdateDeploymentInput!, $agencyID: Int) {
    result: updateDeployment(update: $update, agencyID: $agencyID) {
      id
      id2
      date
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      workHistoryID
      startDatetime
      endDatetime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDeploymentTemplate = /* GraphQL */ `
  mutation CreateDeploymentTemplate($create: CreateDeploymentTemplateInput!) {
    result: createDeploymentTemplate(create: $create) {
      id
      id2
      securityAgencyID
      title
      description
      lastUpdatedAccountID
      lastUpdatedStaffProfileID
      lastUpdatedStaffProfileName
      lastUpdatedStaffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createMultipleDeploymentTemplate = /* GraphQL */ `
  mutation CreateMultipleDeploymentTemplate(
    $createDeploymentTemplate: CreateDeploymentTemplateInput
    $createDeploymentTemplateDetail: [CreateMultipleDeploymentTemplateDetailInput]!
    $agencyID: Int
  ) {
    result: createMultipleDeploymentTemplate(
      createDeploymentTemplate: $createDeploymentTemplate
      createDeploymentTemplateDetail: $createDeploymentTemplateDetail
      agencyID: $agencyID
    )
  }
`;
export const updateDeploymentTemplate = /* GraphQL */ `
  mutation UpdateDeploymentTemplate($update: UpdateDeploymentTemplateInput!) {
    result: updateDeploymentTemplate(update: $update) {
      id
      id2
      securityAgencyID
      title
      description
      lastUpdatedAccountID
      lastUpdatedStaffProfileID
      lastUpdatedStaffProfileName
      lastUpdatedStaffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createDeploymentTemplateDetail = /* GraphQL */ `
  mutation CreateDeploymentTemplateDetail(
    $create: CreateDeploymentTemplateDetailInput!
  ) {
    result: createDeploymentTemplateDetail(create: $create) {
      id
      id2
      deploymentTemplateID
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      startTime
      endTime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateDeploymentTemplateDetail = /* GraphQL */ `
  mutation UpdateDeploymentTemplateDetail(
    $update: UpdateDeploymentTemplateDetailInput!
  ) {
    result: updateDeploymentTemplateDetail(update: $update) {
      id
      id2
      deploymentTemplateID
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      startTime
      endTime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMultipleDeployment = /* GraphQL */ `
  mutation UpdateMultipleDeployment(
    $create: [CreateMultipleDeploymentInput]
    $update: [UpdateMultipleDeploymentInput]
    $agencyID: Int
    $date: String
  ) {
    result: updateMultipleDeployment(
      create: $create
      update: $update
      agencyID: $agencyID
      date: $date
    ) {
      id
      id2
      date
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      workHistoryID
      startDatetime
      endDatetime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateMultipleDeploymentTemplate = /* GraphQL */ `
  mutation UpdateMultipleDeploymentTemplate(
    $updateDeploymentTemplate: UpdateDeploymentTemplateInput
    $create: [CreateMultipleDeploymentTemplateDetailInput]
    $update: [UpdateMultipleDeploymentTemplateDetailInput]
    $agencyID: Int
  ) {
    result: updateMultipleDeploymentTemplate(
      updateDeploymentTemplate: $updateDeploymentTemplate
      create: $create
      update: $update
      agencyID: $agencyID
    )
  }
`;
export const createIncidentReport = /* GraphQL */ `
  mutation CreateIncidentReport(
    $create: CreateIncidentReportInput!
    $agencyID: Int
  ) {
    result: createIncidentReport(create: $create, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateIncidentReport = /* GraphQL */ `
  mutation UpdateIncidentReport(
    $update: UpdateIncidentReportInput!
    $agencyID: Int
  ) {
    result: updateIncidentReport(update: $update, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createIncidentDetail = /* GraphQL */ `
  mutation CreateIncidentDetail(
    $create: CreateIncidentDetailInput!
    $agencyID: Int
  ) {
    result: createIncidentDetail(create: $create, agencyID: $agencyID) {
      id
      incidentReportID
      accountID
      staffProfileID
      designation
      staffProfileName
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateIncidentDetail = /* GraphQL */ `
  mutation UpdateIncidentDetail(
    $update: UpdateIncidentDetailInput!
    $agencyID: Int
  ) {
    result: updateIncidentDetail(update: $update, agencyID: $agencyID) {
      id
      incidentReportID
      accountID
      staffProfileID
      designation
      staffProfileName
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createIncidentFile = /* GraphQL */ `
  mutation CreateIncidentFile(
    $create: CreateIncidentFileInput!
    $agencyID: Int
  ) {
    result: createIncidentFile(create: $create, agencyID: $agencyID) {
      id
      incidentReportID
      incidentDetailID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateIncidentFile = /* GraphQL */ `
  mutation UpdateIncidentFile(
    $update: UpdateIncidentFileInput!
    $agencyID: Int
  ) {
    result: updateIncidentFile(update: $update, agencyID: $agencyID) {
      id
      incidentReportID
      incidentDetailID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createIncidentComment = /* GraphQL */ `
  mutation CreateIncidentComment(
    $create: CreateIncidentCommentInput!
    $agencyID: Int
  ) {
    result: createIncidentComment(create: $create, agencyID: $agencyID) {
      id
      incidentReportID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateIncidentComment = /* GraphQL */ `
  mutation UpdateIncidentComment(
    $update: UpdateIncidentCommentInput!
    $agencyID: Int
  ) {
    result: updateIncidentComment(update: $update, agencyID: $agencyID) {
      id
      incidentReportID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createOccurrence = /* GraphQL */ `
  mutation CreateOccurrence($create: CreateOccurrenceInput!, $agencyID: Int) {
    result: createOccurrence(create: $create, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      reportedByAccountID
      reportedByStaffProfileID
      sn
      date
      datetime
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateOccurrence = /* GraphQL */ `
  mutation UpdateOccurrence($update: UpdateOccurrenceInput!, $agencyID: Int) {
    result: updateOccurrence(update: $update, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      reportedByAccountID
      reportedByStaffProfileID
      sn
      date
      datetime
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPaymentPlan = /* GraphQL */ `
  mutation CreatePaymentPlan($create: CreatePaymentPlanInput!) {
    result: createPaymentPlan(create: $create) {
      id
      uid
      name
      description
      amount
      effectFrom
      effectTo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePaymentPlan = /* GraphQL */ `
  mutation UpdatePaymentPlan($update: UpdatePaymentPlanInput!) {
    result: updatePaymentPlan(update: $update) {
      id
      uid
      name
      description
      amount
      effectFrom
      effectTo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSecuritySite = /* GraphQL */ `
  mutation CreateSecuritySite($create: CreateSecuritySiteInput!) {
    result: createSecuritySite(create: $create) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSecuritySite = /* GraphQL */ `
  mutation UpdateSecuritySite($update: UpdateSecuritySiteInput!) {
    result: updateSecuritySite(update: $update) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSecuritySiteReport = /* GraphQL */ `
  mutation CreateSecuritySiteReport($create: CreateSecuritySiteReportInput!) {
    result: createSecuritySiteReport(create: $create) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      type
      status
      securitySiteID
      reportedBy
      acceptedBy
      acceptedTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSecuritySiteReport = /* GraphQL */ `
  mutation UpdateSecuritySiteReport($update: UpdateSecuritySiteReportInput!) {
    result: updateSecuritySiteReport(update: $update) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      type
      status
      securitySiteID
      reportedBy
      acceptedBy
      acceptedTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSecurityAgency = /* GraphQL */ `
  mutation CreateSecurityAgency($create: CreateSecurityAgencyInput!) {
    result: createSecurityAgency(create: $create) {
      id
      uid
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSecurityAgency = /* GraphQL */ `
  mutation UpdateSecurityAgency($update: UpdateSecurityAgencyInput!) {
    result: updateSecurityAgency(update: $update) {
      id
      uid
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSiteProfile = /* GraphQL */ `
  mutation CreateSiteProfile($create: CreateSiteProfileInput!, $agencyID: Int) {
    result: createSiteProfile(create: $create, agencyID: $agencyID) {
      id
      securitySiteID
      securityAgencyID
      managementAgencyID
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      status
      phoneNo
      siteHistoryID
      siteHistoryStart
      siteHistoryEnd
      siteHistoryStatus
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      clockingPointID
      clockingPointName
      clockingPointTag
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSiteProfile = /* GraphQL */ `
  mutation UpdateSiteProfile($update: UpdateSiteProfileInput!, $agencyID: Int) {
    result: updateSiteProfile(update: $update, agencyID: $agencyID) {
      id
      securitySiteID
      securityAgencyID
      managementAgencyID
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      status
      phoneNo
      siteHistoryID
      siteHistoryStart
      siteHistoryEnd
      siteHistoryStatus
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      clockingPointID
      clockingPointName
      clockingPointTag
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSiteHistory = /* GraphQL */ `
  mutation CreateSiteHistory($create: CreateSiteHistoryInput!, $agencyID: Int) {
    result: createSiteHistory(create: $create, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      siteProfileID
      siteProfileName
      siteProfileMCSTNo
      siteProfileAddress
      siteProfileUnitNo
      siteProfilePostal
      siteProfileTier
      siteProfileStrataLot
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      start
      end
      status
      clockingPointID
      clockingPointName
      clockingPointTag
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSiteHistory = /* GraphQL */ `
  mutation UpdateSiteHistory($update: UpdateSiteHistoryInput!, $agencyID: Int) {
    result: updateSiteHistory(update: $update, agencyID: $agencyID) {
      id
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      siteProfileID
      siteProfileName
      siteProfileMCSTNo
      siteProfileAddress
      siteProfileUnitNo
      siteProfilePostal
      siteProfileTier
      siteProfileStrataLot
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      start
      end
      status
      clockingPointID
      clockingPointName
      clockingPointTag
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createSOPVersion = /* GraphQL */ `
  mutation CreateSOPVersion($create: CreateSOPVersionInput!) {
    result: createSOPVersion(create: $create) {
      id
      standardOperatingProcedureID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateSOPVersion = /* GraphQL */ `
  mutation UpdateSOPVersion($update: UpdateSOPVersionInput!) {
    result: updateSOPVersion(update: $update) {
      id
      standardOperatingProcedureID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createStandardOperatingProcedure = /* GraphQL */ `
  mutation CreateStandardOperatingProcedure(
    $create: CreateStandardOperatingProcedureInput!
  ) {
    result: createStandardOperatingProcedure(create: $create) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      title
      description
      SOPVersionID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStandardOperatingProcedure = /* GraphQL */ `
  mutation UpdateStandardOperatingProcedure(
    $update: UpdateStandardOperatingProcedureInput!
  ) {
    result: updateStandardOperatingProcedure(update: $update) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      title
      description
      SOPVersionID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStaffProfile = /* GraphQL */ `
  mutation UpdateStaffProfile($update: UpdateStaffProfileInput!) {
    result: updateStaffProfile(update: $update) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      name
      staffNo
      cognitoGroup
      designation
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      permission
      status
      workHistoryID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      dob
      gender
      phoneNo
      email
      preferred_username
      last4ID
      plrdGradeID
      emPassTag
      plrdName
      plrdCode
      emStart
      emEnd
      emStatus
      emType
      plrdDateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStaffProfile2 = /* GraphQL */ `
  mutation UpdateStaffProfile2(
    $update: UpdateStaffProfileInput!
    $updateUser: UpdateUserInput
    $agencyID: Int
  ) {
    result: updateStaffProfile2(
      update: $update
      updateUser: $updateUser
      agencyID: $agencyID
    ) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      name
      staffNo
      cognitoGroup
      designation
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      permission
      status
      workHistoryID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      dob
      gender
      phoneNo
      email
      preferred_username
      last4ID
      plrdGradeID
      emPassTag
      plrdName
      plrdCode
      emStart
      emEnd
      emStatus
      emType
      plrdDateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStaffProfilePassTag = /* GraphQL */ `
  mutation UpdateStaffProfilePassTag(
    $update: UpdateStaffProfileInput!
    $emPassTag: String
  ) {
    result: updateStaffProfilePassTag(update: $update, emPassTag: $emPassTag) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      name
      staffNo
      cognitoGroup
      designation
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      permission
      status
      workHistoryID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      dob
      gender
      phoneNo
      email
      preferred_username
      last4ID
      plrdGradeID
      emPassTag
      plrdName
      plrdCode
      emStart
      emEnd
      emStatus
      emType
      plrdDateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createStaffTrainingModule = /* GraphQL */ `
  mutation CreateStaffTrainingModule($create: CreateStaffTrainingModuleInput!) {
    result: createStaffTrainingModule(create: $create) {
      id
      accountID
      staffProfileID
      securityAgencyID
      trainingModuleID
      title
      trainingStart
      trainingEnd
      learningHours
      expiry
      uid
      code
      module
      level
      trainingModuleTitle
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateStaffTrainingModule = /* GraphQL */ `
  mutation UpdateStaffTrainingModule($update: UpdateStaffTrainingModuleInput!) {
    result: updateStaffTrainingModule(update: $update) {
      id
      accountID
      staffProfileID
      securityAgencyID
      trainingModuleID
      title
      trainingStart
      trainingEnd
      learningHours
      expiry
      uid
      code
      module
      level
      trainingModuleTitle
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket($create: CreateTicketInput!) {
    result: createTicket(create: $create) {
      id
      uid
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      status
      category
      title
      description
      priority
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket($update: UpdateTicketInput!) {
    result: updateTicket(update: $update) {
      id
      uid
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      status
      category
      title
      description
      priority
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createTicketComment = /* GraphQL */ `
  mutation CreateTicketComment($create: CreateTicketCommentInput!) {
    result: createTicketComment(create: $create) {
      id
      ticketID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateTicketComment = /* GraphQL */ `
  mutation UpdateTicketComment($update: UpdateTicketCommentInput!) {
    result: updateTicketComment(update: $update) {
      id
      ticketID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createTicketFile = /* GraphQL */ `
  mutation CreateTicketFile($create: CreateTicketFileInput!) {
    result: createTicketFile(create: $create) {
      id
      ticketID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateTicketFile = /* GraphQL */ `
  mutation UpdateTicketFile($update: UpdateTicketFileInput!) {
    result: updateTicketFile(update: $update) {
      id
      ticketID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($update: UpdateUserInput!) {
    result: updateUser(update: $update) {
      id
      accountID
      name
      dob
      gender
      phoneNo
      email
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      status
      last4ID
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      emPassTag
      plrdGradeID
      plrdName
      plrdCode
      notificationToken
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createWorkHistory = /* GraphQL */ `
  mutation CreateWorkHistory($create: CreateWorkHistoryInput!, $agencyID: Int) {
    result: createWorkHistory(create: $create, agencyID: $agencyID) {
      id
      accountID
      staffProfileID
      siteHistoryID
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      designation
      emStart
      emEnd
      emStatus
      emType
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateWorkHistory = /* GraphQL */ `
  mutation UpdateWorkHistory($update: UpdateWorkHistoryInput!, $agencyID: Int) {
    result: updateWorkHistory(update: $update, agencyID: $agencyID) {
      id
      accountID
      staffProfileID
      siteHistoryID
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      designation
      emStart
      emEnd
      emStatus
      emType
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPLRDHistory = /* GraphQL */ `
  mutation CreatePLRDHistory($create: CreatePLRDHistoryInput!, $agencyID: Int) {
    result: createPLRDHistory(create: $create, agencyID: $agencyID) {
      id
      accountID
      plrdGradeID
      plrdName
      plrdCode
      dateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePLRDHistory = /* GraphQL */ `
  mutation UpdatePLRDHistory($update: UpdatePLRDHistoryInput!, $agencyID: Int) {
    result: updatePLRDHistory(update: $update, agencyID: $agencyID) {
      id
      accountID
      plrdGradeID
      plrdName
      plrdCode
      dateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createPurposeOfVisit = /* GraphQL */ `
  mutation CreatePurposeOfVisit($create: CreatePurposeOfVisitInput!) {
    result: createPurposeOfVisit(create: $create) {
      id
      securityAgencyID
      managementAgencyID
      name
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updatePurposeOfVisit = /* GraphQL */ `
  mutation UpdatePurposeOfVisit($update: UpdatePurposeOfVisitInput!) {
    result: updatePurposeOfVisit(update: $update) {
      id
      securityAgencyID
      managementAgencyID
      name
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const createVisitorRecord = /* GraphQL */ `
  mutation CreateVisitorRecord(
    $create: CreateVisitorRecordInput!
    $agencyID: Int
  ) {
    result: createVisitorRecord(create: $create, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      type
      name
      phoneNo
      purpose
      block
      unit
      vehicleNo
      remarks
      inTimestamp
      outTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const updateVisitorRecord = /* GraphQL */ `
  mutation UpdateVisitorRecord(
    $update: UpdateVisitorRecordInput!
    $agencyID: Int
  ) {
    result: updateVisitorRecord(update: $update, agencyID: $agencyID) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      type
      name
      phoneNo
      purpose
      block
      unit
      vehicleNo
      remarks
      inTimestamp
      outTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const adminChangePassword = /* GraphQL */ `
  mutation AdminChangePassword($username: String!, $password: String!) {
    adminChangePassword(username: $username, password: $password)
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $username: String!
    $group: String
    $email: String
    $phoneNo: String!
    $name: String
    $gender: String
  ) {
    result: createCognitoUser(
      username: $username
      group: $group
      email: $email
      phoneNo: $phoneNo
      name: $name
      gender: $gender
    )
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $username: String!
    $attributes: String
    $forceSignOut: Int
    $accountStatus: String
  ) {
    result: updateCognitoUser(
      username: $username
      attributes: $attributes
      forceSignOut: $forceSignOut
      accountStatus: $accountStatus
    )
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $create: CreateStaffInput!
    $createWorkHistory: CreateWorkHistoryInput
    $password: String
  ) {
    result: createStaff(
      create: $create
      createWorkHistory: $createWorkHistory
      password: $password
    )
  }
`;
export const createClientUser = /* GraphQL */ `
  mutation CreateClientUser(
    $create: CreateClientUserInput!
    $password: String
  ) {
    result: createClientUser(create: $create, password: $password)
  }
`;
export const createAgencyMasterAdmin = /* GraphQL */ `
  mutation CreateAgencyMasterAdmin(
    $create: CreateStaffInput!
    $password: String
  ) {
    result: createAgencyMasterAdmin(create: $create, password: $password)
  }
`;
export const createFeedbackEmail = /* GraphQL */ `
  mutation CreateFeedbackEmail($create: CreateFeedbackEmailInput!) {
    result: createFeedbackEmail(create: $create)
  }
`;
export const submitClockingReport = /* GraphQL */ `
  mutation SubmitClockingReport(
    $updateClockingReport: UpdateClockingReportInput
    $createClockingReportDetail: [CreateClockingReportDetailInput]
  ) {
    result: submitClockingReport(
      updateClockingReport: $updateClockingReport
      createClockingReportDetail: $createClockingReportDetail
    ) {
      id
      securitySiteID
      securityAgencyID
      siteProfileID
      clockingRouteID
      accountID
      staffProfileID
      status
      remark
      start
      end
      totalPoint
      clockedPoint
      islandWide
      securitySiteName
      securityAgencyName
      siteProfileName
      clockingRouteName
      staffNo
      staffProfileName
      staffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const submitAttendance = /* GraphQL */ `
  mutation SubmitAttendance(
    $update: UpdateAttendanceInput
    $siteTag: String
    $staffTag: String
    $state: String
  ) {
    result: submitAttendance(
      update: $update
      siteTag: $siteTag
      staffTag: $staffTag
      state: $state
    ) {
      id
      accountID
      workHistoryID
      securityAgencyID
      staffProfileID
      staffName
      staffNo
      timeIn
      timeInSiteID
      timeInSiteProfileID
      timeInKey
      timeInBucket
      timeInRegion
      timeInLatitude
      timeInLongitude
      timeInRemark
      timeInSiteName
      timeInSiteProfileName
      timeOut
      timeOutSiteID
      timeOutSiteProfileID
      timeOutKey
      timeOutBucket
      timeOutRegion
      timeOutLatitude
      timeOutLongitude
      timeOutRemark
      timeOutSiteName
      timeOutSiteProfileName
      approvalStatus
      approvedDuration
      reviewedBy
      reviewedTimestamp
      remark
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const submitIncident = /* GraphQL */ `
  mutation SubmitIncident(
    $updateIncidentReport: UpdateIncidentReportInput
    $updateIncidentDetail: UpdateIncidentDetailInput
    $updateIncidentFile: [UpdateIncidentFileInput]
  ) {
    result: submitIncident(
      updateIncidentReport: $updateIncidentReport
      updateIncidentDetail: $updateIncidentDetail
      updateIncidentFile: $updateIncidentFile
    ) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      accountID
      agencyID
      title
      message
      obj
      read
      type
      createdOn
      updatedOn
      deleted
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    result: createNotification(input: $input, condition: $condition) {
      id
      accountID
      agencyID
      title
      message
      obj
      read
      type
      createdOn
      updatedOn
      deleted
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    result: updateNotification(input: $input, condition: $condition) {
      id
      accountID
      agencyID
      title
      message
      obj
      read
      type
      createdOn
      updatedOn
      deleted
    }
  }
`;
