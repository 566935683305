const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:cd6a1070-8335-4523-852c-ccd14121c969",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_Onh4xebvd",
    "aws_user_pools_web_client_id": "43o8ub9srt7vc8u1bs9ftejqrj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://gf3yhpphdnh53kk5vj2abvx6fu.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "isap141230-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_login_mechanism": [
        "EMAIL",
        "PREFERRED_USERNAME",
        "PHONE_NUMBER"
    ]
};


export default awsmobile;