import React, { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Empty, Form, Image, Input, notification, Popconfirm, Select, Spin, Table, Tag, Typography } from 'antd'
import moment from 'moment'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'

import { listAttendance } from '../backend/graphql/queries'
import { updateAttendance } from '../backend/graphql/mutations'
// import { DataContext } from "../contexts/DataContext"
import { AppContext } from '../contexts/AppContext'
// import CardAttendance from './Component-Card-Attendance';

import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import FilterAppliedAttendance from './Component-FilterApplied-Attendance'
import FilterAttendance from './Component-Filter-Attendance'

const { Option } = Select

const AttendanceRecords = () => {
    // const { dataState } = useContext(DataContext)
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [formEditableTable] = Form.useForm();
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        editingId: '',
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [values, setValues] = useState({
        search: '',
        dateRange: [],
        site: 0,
        approvalStatus: 0,
    })

    const getAttendanceRecords = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true,
        })
        try {
            let filter = {
                securityAgencyID: {
                    // eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    eq: appState.agencyID
                },
                or: [{
                    timeInSiteProfileID: {
                        eq: appState.siteProfile.siteProfileID
                    }
                }, {
                    timeOutSiteProfileID: {
                        eq: appState.siteProfile.siteProfileID
                    }
                }],
                and: [
                    {
                        timeIn: { ge: values.dateRange[0].toISOString().split('.')[0] }
                    },
                    {
                        timeIn: { le: values.dateRange[1].toISOString().split('.')[0] }
                    },
                    {
                        timeOut: { attributeExists: true },
                    },
                ]
            }

            if (values.search !== '') {
                filter = {
                    ...filter,
                    or: [
                        { staffNo: { contains: values.search } },
                        { staffName: { contains: values.search } },
                    ]
                }
            }

            if (values.approvalStatus !== 0) {
                // console.log("approval status", values.approvalStatus);
                filter = {
                    ...filter,
                    approvalStatus: { eq: values.approvalStatus }
                }
            }

            const listAttendanceDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    // agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    agencyID: appState.agencyID
                },
                filter: filter
            }
            // console.log('listAttendanceDetails', listAttendanceDetails);
            const result = await API.graphql({
                query: listAttendance,
                variables: listAttendanceDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            setTable({
                ...table,
                editingId: '',
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve attendance records'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.attendanceRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    const tabulateDisplayListData = async (table) => {
        let array = []
        for (let i = 0; i < table.data.length; i++) {
            let object = table.data[i]
            // console.log("object", object);
            if (table.data[i].timeOut !== null) {
                const totalDurationMilliseconds = new Date(table.data[i].timeOut.replace(/-/g, '/') + ' UTC') - new Date(table.data[i].timeIn.replace(/-/g, '/') + ' UTC')
                const totalDurationHours = totalDurationMilliseconds / 1000 / 60 / 60
                const totalDurationHoursSuggested = Math.round(totalDurationHours * 2) / 2
                const totalMinutesSuggested = totalDurationHoursSuggested * 60
                // console.log(totalDurationMilliseconds);
                // console.log(totalDurationHoursSuggested);
                if (table.data[i].approvalStatus === 'PENDING') {
                    object = {
                        ...table.data[i],
                        approvedDuration: totalMinutesSuggested
                    }
                }
            }
            let imageSourceTimeIn = ''
            let imageSourceTimeOut = ''
            if (object.timeInBucket !== null && object.timeInBucket !== undefined && object.timeInBucket !== '') {
                // imageSourceTimeIn = await Storage.get(object.timeInBucket)
                imageSourceTimeIn = await Storage.get(object.timeInKey)
                // console.log("imageSourceTimeIn", imageSourceTimeIn);
                object = {
                    ...object,
                    imageSourceTimeIn
                }
            }
            if (object.timeOutBucket !== null && object.timeOutBucket !== undefined && object.timeOutBucket !== '') {
                // imageSourceTimeOut = await Storage.get(object.timeOutBucket)
                imageSourceTimeOut = await Storage.get(object.timeOutKey)
                // console.log("imageSourceTimeOut", imageSourceTimeOut);
                object = {
                    ...object,
                    imageSourceTimeOut
                }
            }
            array.push(object)
        }
        setDisplayList(array)
        setTable({
            ...table,
            loading: false
        })
    }

    const isEditing = (record) => record.id === table.editingId

    useEffect(() => {
        // console.log(moment());
        // console.log(moment().format("MMM"));
        // form.setFieldsValue({
        //     ...values,
        //     month: moment()
        // })

        const currentDatetime = moment()
        let _values = {}
        if (sessionStorage.getItem('isap_agency_attendance_records') !== null) {
            // console.log(moment(JSON.parse(sessionStorage.getItem("isap_agency_attendance_records")).dateRange[0]));
            _values = {
                ...values,
                ...JSON.parse(sessionStorage.getItem('isap_agency_attendance_records')),
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(JSON.parse(sessionStorage.getItem('isap_agency_attendance_records')).dateRange[0]),
                    moment(JSON.parse(sessionStorage.getItem('isap_agency_attendance_records')).dateRange[1]),
                ]
            }
        }
        else {
            _values = {
                ...values,
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 00:00:00'))),
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 23:59:59'))),
                ]
            }
        }
        setValues(_values)
        getAttendanceRecords(table.pagination, _values)

        actionSetPageTitle('Attendance - Records')

        return () => {
            actionSetPageTitle('')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1) {
            tabulateDisplayListData(table)
            // let array = []
            // for (let i = 0; i < table.data.length; i++) {
            //     let object = table.data[i]
            //     console.log("object", object);
            //     if (table.data[i].timeOut !== null) {
            //         const totalDurationMilliseconds = new Date(table.data[i].timeOut.replace(/-/g, "/") + " UTC") - new Date(table.data[i].timeIn.replace(/-/g, "/") + " UTC")
            //         const totalDurationHours = totalDurationMilliseconds / 1000 / 60 / 60
            //         const totalDurationHoursSuggested = Math.round(totalDurationHours * 2) / 2
            //         const totalMinutesSuggested = totalDurationHoursSuggested * 60
            //         // console.log(totalDurationMilliseconds);
            //         // console.log(totalDurationHoursSuggested);
            //         if (table.data[i].approvalStatus === "PENDING") {
            //             object = {
            //                 ...table.data[i],
            //                 approvedDuration: totalMinutesSuggested
            //             }
            //         }
            //     }
            //     array.push(object)
            // }
            // setDisplayList(array)
            // setTable({
            //     ...table,
            //     loading: false
            // })
        }
    }, [table.data])

    const handleTableChange = (paginate) => {
        getAttendanceRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, values)
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const handleTableEdit = (record) => {
        // console.log("edit", record);
        formEditableTable.setFieldsValue({
            ...record,
        });
        setTable({
            ...table,
            editingId: record.id
        })
    };

    const handleTableCancel = () => {
        setTable({
            ...table,
            editingId: ''
        })
    };

    const handleTableSave = async (_values) => {
        // console.log("SAVE FOR ID", _values);
        await formEditableTable.validateFields()
        setTable({
            ...table,
            loading: true,
        })
        try {
            const attendanceDetails = {
                update: {
                    id: table.editingId,
                    timeInSiteProfileID: _values.timeInSiteProfileID,
                    timeInSiteID: appState.siteList?.find(s => s.id == _values.timeInSiteProfileID)?.securitySiteID,
                    timeOutSiteProfileID: _values.timeOutSiteProfileID,
                    timeOutSiteID: appState.siteList?.find(s => s.id == _values.timeOutSiteProfileID)?.securitySiteID,
                    approvalStatus: _values.approvalStatus,
                },
                // agencyID: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                agencyID: appState.agencyID
            }
            if (Number(_values.approvedDuration) ?? false) {
                attendanceDetails.update.approvedDuration = _values.approvedDuration;
            }

            // console.log("attendanceDetails", attendanceDetails);
            const result = await API.graphql({
                query: updateAttendance,
                variables: attendanceDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: 'Updated successfully'
            })
            getAttendanceRecords(table.pagination, values)
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to update attendance record'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     clearTimeout(timer)
        //     notification.success({
        //         message: "Updated successfully"
        //     })
        //     getAttendanceRecords(table.pagination, values)
        // }, 1000)

        // try {
        //   const row = await form.validateFields();
        //   const newData = [...data];
        //   const index = newData.findIndex((item) => key === item.key);

        //   if (index > -1) {
        //     const item = newData[index];
        //     newData.splice(index, 1, { ...item, ...row });
        //     setData(newData);
        //     setEditingKey('');
        //   } else {
        //     newData.push(row);
        //     setData(newData);
        //     setEditingKey('');
        //   }
        // } catch (errInfo) {
        //   console.log('Validate Failed:', errInfo);
        // }
    };

    const onFinish = (values) => {
        // console.log('on finish', values);
        let _values = {
            ...values,
            dateRange: [
                moment(values.dateRange[0].format('YYYY-MM-DD 00:00:00')),
                moment(values.dateRange[1].format('YYYY-MM-DD 23:59:59')),
            ]
        }
        sessionStorage.setItem('isap_agency_attendance_records', JSON.stringify(_values))
        setValues(_values)
        getAttendanceRecords(table.pagination, _values)
    }

    const renderListOptions = (array) => {
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        // console.log("dataIndex", dataIndex);
        const inputNode =
            (inputType === 'inputNumber' ?
                <Input style={{ width: '100px' }} autoComplete="off" addonAfter="min" /> : (
                    inputType === 'datePicker' ? <DatePicker showTime format={dateFormatList} /> :
                        <Select
                            style={{ width: '100px' }}
                            showSearch
                            placeholder="Select status"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {/* <Option value={0}>All</Option>
                <Option value={1}>Option</Option> */}
                            {
                                dataIndex === 'timeInSiteProfileID' || dataIndex === 'timeOutSiteProfileID' ? (
                                    renderListOptions(appState.siteList)
                                ) : (
                                    dataIndex === 'approvalStatus' ? (
                                        <>
                                            <Option value={'PENDING'}>Pending</Option>
                                            <Option value={'APPROVED'}>Approved</Option>
                                            <Option value={'REJECTED'}>Rejected</Option>
                                        </>
                                    ) : (
                                        ''
                                    )
                                )
                            }
                            {/* {renderListOptions(appState.plrdGradeList)} */}
                        </Select>
                )
            )
        // console.log(inputNode);
        return (
            <td {...restProps}>
                {editing ? (
                    inputType === 'inputNumber' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^\d+(\.\d+)?$/),
                                    message: 'Please provide a valid input',
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    )
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        // {
        //     title: "Staff Num",
        //     dataIndex: "staffNo"
        // },
        {
            title: 'Staff Name',
            dataIndex: 'staffName'
        },
        {
            title: 'Site In',
            dataIndex: 'timeInSiteProfileID',
            editable: true,
            inputType: 'select',
            render: (text, record) => {
                return (
                    record.timeInSiteProfileName
                )
            }
        },
        {
            title: 'Time In',
            dataIndex: 'timeIn',
            // editable: true,
            inputType: 'datePicker',
            render: (text, record) => {
                return (
                    moment.utc(text).local().format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Site Out',
            dataIndex: 'timeOutSiteProfileID',
            editable: true,
            inputType: 'select',
            render: (text, record) => {
                return (
                    record.timeOutSiteProfileName
                )
            }
        },
        {
            title: 'Time Out',
            dataIndex: 'timeOut',
            // editable: true,
            inputType: 'datePicker',
            render: (text, record) => {
                return (
                    text !== null && text !== undefined && text !== '' ? (
                        moment.utc(text).local().format('DD MMM YYYY (ddd), HH:mm')
                    ) : ('Not Available')
                )
            }
        },
        {
            title: 'Total Duration',
            dataIndex: 'totalDuration',
            render: (text, record) => {
                // console.log("text", text);
                // console.log("record", record);
                if (record.timeOut !== null && record.timeOut !== undefined && record.timeOut !== '') {
                    const totalDurationMilliseconds = new Date(record.timeOut.replace(/-/g, '/') + ' UTC') - new Date(record.timeIn.replace(/-/g, '/') + ' UTC')
                    const totalDurationHours = totalDurationMilliseconds / 1000 / 60 / 60
                    const totalDurationMinutes = totalDurationHours * 60
                    const totalDurationDisplayHours = Math.floor(totalDurationMilliseconds / 1000 / 60 / 60)
                    const totalDurationDisplayMinutes = (totalDurationMilliseconds / 1000 / 60 % 60).toFixed(1)
                    const editable = isEditing(record)
                    if (editable) {
                        return (
                            <div>
                                {totalDurationDisplayHours}h {totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes + 'min' : ''}
                                <br />
                                <i>({totalDurationMinutes}min)</i>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                {totalDurationDisplayHours}h {totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes + 'min' : ''}
                            </div>
                        )
                    }
                }
                else {
                    return ('Not Available')
                }
            }
        },
        {
            title: 'Attendance Images',
            dataIndex: 'attendanceImages',
            render: (text, record) => {
                return (
                    <div className="row">
                        <Image.PreviewGroup>
                            <Image
                                width={100}
                                src={record.imageSourceTimeIn !== null ? record.imageSourceTimeIn : ''}
                                fallback={imgEmptyImage192}
                            />
                            &nbsp;&nbsp;
                            <Image
                                width={100}
                                src={record.imageSourceTimeOut !== null ? record.imageSourceTimeOut : ''}
                                fallback={imgEmptyImage192}
                            />
                        </Image.PreviewGroup>
                    </div>
                )
            }
        },
        {
            title: 'Approval Status',
            dataIndex: 'approvalStatus',
            editable: true,
            inputType: 'select',
            render: (text, record) => {
                const colour = text === 'APPROVED' ? 'green' : 'yellow'
                return (
                    <Tag color={colour}>
                        {text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Approved Duration',
            dataIndex: 'approvedDuration',
            editable: true,
            inputType: 'inputNumber',
            render: (text, record) => {
                // console.log(record);
                const totalDurationDisplayHours = Math.floor(text / 60)
                const totalDurationDisplayMinutes = text % 60
                return (
                    record.approvalStatus === 'APPROVED' ? (
                        <div>
                            {totalDurationDisplayHours}h {totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes + 'min' : ''}
                        </div>
                    ) : (
                        ''
                    )
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className="table-cell-editabletable-action">
                        <Popconfirm title={<div>Are you sure you?</div>} onConfirm={() => handleTableSave(formEditableTable.getFieldsValue())} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Popconfirm>
                        <Popconfirm title={<div>Are you sure you?<br /> All changes will be discarded.</div>} onConfirm={handleTableCancel} okText="Yes" cancelText="No">
                            <a>Cancel</a>
                        </Popconfirm>
                    </div>
                ) : (
                    <a disabled={table.editingId !== ''} onClick={() => handleTableEdit(record)}>
                        Edit
                    </a>
                );
            },
        },
    ]

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        // console.log("col", col);
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    // const renderCards = (array) => {
    //     const cards = array.map((item) => {
    //         // console.log(item);
    //         return (
    //             <CardAttendance item={item} key={item.id} />
    //         )
    //     })
    //     return cards
    // }

    // const renderCardAttendanceRecords = (array) => {
    //     return (
    //         (array.length === 0) ? (
    //             <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //         ) : (
    //             renderCards(array)
    //         )
    //     )
    // }

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Attendance</Tag>
                        </div>
                    </div>
                } />
                <FilterAttendance onFinish={onFinish} values={values} />
                <FilterAppliedAttendance onFinish={onFinish} values={values} />
            </div>
            <Form form={formEditableTable} onFinish={handleTableSave}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    // className="above-md"
                    columns={mergedColumns}
                    rowKey={record => record.id}
                    rowClassName="editable-row"
                    dataSource={displayList}
                    pagination={table.pagination}
                    loading={table.loading}
                    scroll={{ x: 1500, y: '55vh' }}
                    // scroll={{ x: 1000 }}
                    onChange={handleTableChange}
                />
            </Form>
            {/* <div className="below-md container-flexgrow" onScroll={handleScoll}>
                <Spin spinning={table.loading}>
                    {renderCardAttendanceRecords(displayList)}
                </Spin>
            </div> */}
        </div>
    )
}

export default AttendanceRecords