// g, l, s, 5 customised
// github ca57733

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAttendance = /* GraphQL */ `
  query GetAttendance($id: Int!) {
    result: getAttendance(id: $id) {
      id
      accountID
      workHistoryID
      securityAgencyID
      staffProfileID
      staffName
      staffNo
      timeIn
      timeInSiteID
      timeInSiteProfileID
      timeInKey
      timeInBucket
      timeInRegion
      timeInLatitude
      timeInLongitude
      timeInRemark
      timeInSiteName
      timeInSiteProfileName
      timeOut
      timeOutSiteID
      timeOutSiteProfileID
      timeOutKey
      timeOutBucket
      timeOutRegion
      timeOutLatitude
      timeOutLongitude
      timeOutRemark
      timeOutSiteName
      timeOutSiteProfileName
      approvalStatus
      approvedDuration
      reviewedBy
      reviewedTimestamp
      remark
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listAttendance = /* GraphQL */ `
  query ListAttendance(
    $filter: ListAttendanceFilterInput
    $pagination: PaginationInput
  ) {
    result: listAttendance(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        workHistoryID
        securityAgencyID
        staffProfileID
        staffName
        staffNo
        timeIn
        timeInSiteID
        timeInSiteProfileID
        timeInKey
        timeInBucket
        timeInRegion
        timeInLatitude
        timeInLongitude
        timeInRemark
        timeInSiteName
        timeInSiteProfileName
        timeOut
        timeOutSiteID
        timeOutSiteProfileID
        timeOutKey
        timeOutBucket
        timeOutRegion
        timeOutLatitude
        timeOutLongitude
        timeOutRemark
        timeOutSiteName
        timeOutSiteProfileName
        approvalStatus
        approvedDuration
        reviewedBy
        reviewedTimestamp
        remark
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listAttendanceBySite = /* GraphQL */ `
  query ListAttendanceBySite(
    $filter: ListAttendanceBySiteFilterInput!
    $pagination: PaginationInput
  ) {
    result: listAttendanceBySite(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        workHistoryID
        securityAgencyID
        staffProfileID
        staffName
        staffNo
        timeIn
        timeInSiteID
        timeInSiteProfileID
        timeInKey
        timeInBucket
        timeInRegion
        timeInLatitude
        timeInLongitude
        timeInRemark
        timeInSiteName
        timeInSiteProfileName
        timeOut
        timeOutSiteID
        timeOutSiteProfileID
        timeOutKey
        timeOutBucket
        timeOutRegion
        timeOutLatitude
        timeOutLongitude
        timeOutRemark
        timeOutSiteName
        timeOutSiteProfileName
        approvalStatus
        approvedDuration
        reviewedBy
        reviewedTimestamp
        remark
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listAttendanceByStaffTag = /* GraphQL */ `
  query ListAttendanceByStaffTag(
    $filter: ListAttendanceFilterInput
    $pagination: PaginationInput
    $staffTag: String!
  ) {
    result: listAttendanceByStaffTag(
      filter: $filter
      pagination: $pagination
      staffTag: $staffTag
    ) {
      result {
        id
        accountID
        workHistoryID
        securityAgencyID
        staffProfileID
        staffName
        staffNo
        timeIn
        timeInSiteID
        timeInSiteProfileID
        timeInKey
        timeInBucket
        timeInRegion
        timeInLatitude
        timeInLongitude
        timeInRemark
        timeInSiteName
        timeInSiteProfileName
        timeOut
        timeOutSiteID
        timeOutSiteProfileID
        timeOutKey
        timeOutBucket
        timeOutRegion
        timeOutLatitude
        timeOutLongitude
        timeOutRemark
        timeOutSiteName
        timeOutSiteProfileName
        approvalStatus
        approvedDuration
        reviewedBy
        reviewedTimestamp
        remark
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClientProfile = /* GraphQL */ `
  query GetClientProfile($id: Int!) {
    result: getClientProfile(id: $id) {
      id
      securityAgencyID
      managementAgencyID
      clientAgencyID
      permission
      status
      name
      phoneNo
      email
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClientProfile = /* GraphQL */ `
  query ListClientProfile(
    $filter: ListClientProfileFilterInput
    $pagination: PaginationInput
  ) {
    result: listClientProfile(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        managementAgencyID
        clientAgencyID
        permission
        status
        name
        phoneNo
        email
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClientUserProfile = /* GraphQL */ `
  query GetClientUserProfile($id: Int!) {
    result: getClientUserProfile(id: $id) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      clientAgencyID
      clientProfileID
      name
      phoneNo
      email
      permission
      status
      start
      end
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClientUserProfile = /* GraphQL */ `
  query ListClientUserProfile(
    $filter: ListClientUserProfileFilterInput
    $pagination: PaginationInput
  ) {
    result: listClientUserProfile(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        securityAgencyID
        managementAgencyID
        clientAgencyID
        clientProfileID
        name
        phoneNo
        email
        permission
        status
        start
        end
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getCitizenship = /* GraphQL */ `
  query GetCitizenship($id: Int!) {
    result: getCitizenship(id: $id) {
      id
      name
      deleted
    }
  }
`;
export const listCitizenship = /* GraphQL */ `
  query ListCitizenship(
    $filter: ListCitizenshipFilterInput
    $pagination: PaginationInput
  ) {
    result: listCitizenship(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        deleted
      }
    }
  }
`;
export const getClockingPoint = /* GraphQL */ `
  query GetClockingPoint($id: Int!) {
    result: getClockingPoint(id: $id) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      description
      tag
      isAttendance
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClockingPoint = /* GraphQL */ `
  query ListClockingPoint(
    $filter: ListClockingPointFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingPoint(filter: $filter, pagination: $pagination) {
      result {
        id
        securitySiteID
        securitySiteName
        securityAgencyID
        securityAgencyName
        siteProfileID
        siteProfileName
        name
        description
        tag
        isAttendance
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClockingRoute = /* GraphQL */ `
  query GetClockingRoute($id: Int!) {
    result: getClockingRoute(id: $id) {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      routeDetails
      inOrder
      islandWide
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClockingRoute = /* GraphQL */ `
  query ListClockingRoute(
    $filter: ListClockingRouteFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingRoute(filter: $filter, pagination: $pagination) {
      result {
        id
        securitySiteID
        securitySiteName
        securityAgencyID
        securityAgencyName
        siteProfileID
        siteProfileName
        name
        routeDetails
        inOrder
        islandWide
        status
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listClockingRouteDetail = /* GraphQL */ `
  query ListClockingRouteDetail(
    $filter: ListClockingRouteDetailFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingRouteDetail(filter: $filter, pagination: $pagination) {
      result {
        clockingRouteID
        clockingRouteName
        securitySiteID
        securitySiteName
        securityAgencyID
        securityAgencyName
        siteProfileID
        siteProfileName
        routeDetails
        inOrder
        islandWide
        clockingRouteStatus
        clockingPointID
        clockingPointName
        clockingPointDescription
        clockingPointUpdatedOn
        tag
        isAttendance
        deleted
      }
      count
    }
  }
`;
export const getClockingReport = /* GraphQL */ `
  query GetClockingReport($id: Int!) {
    result: getClockingReport(id: $id) {
      id
      securitySiteID
      securityAgencyID
      siteProfileID
      clockingRouteID
      accountID
      staffProfileID
      status
      remark
      start
      end
      totalPoint
      clockedPoint
      islandWide
      securitySiteName
      securityAgencyName
      siteProfileName
      clockingRouteName
      staffNo
      staffProfileName
      staffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClockingReport = /* GraphQL */ `
  query ListClockingReport(
    $filter: ListClockingReportFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingReport(filter: $filter, pagination: $pagination) {
      result {
        id
        securitySiteID
        securityAgencyID
        siteProfileID
        clockingRouteID
        accountID
        staffProfileID
        status
        remark
        start
        end
        totalPoint
        clockedPoint
        islandWide
        securitySiteName
        securityAgencyName
        siteProfileName
        clockingRouteName
        staffNo
        staffProfileName
        staffProfileDesignation
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listClockingReportBySite = /* GraphQL */ `
  query ListClockingReportBySite(
    $filter: ListClockingReportFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingReportBySite(filter: $filter, pagination: $pagination) {
      result {
        id
        securitySiteID
        securityAgencyID
        siteProfileID
        clockingRouteID
        accountID
        staffProfileID
        status
        remark
        start
        end
        totalPoint
        clockedPoint
        islandWide
        securitySiteName
        securityAgencyName
        siteProfileName
        clockingRouteName
        staffNo
        staffProfileName
        staffProfileDesignation
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getClockingReportDetail = /* GraphQL */ `
  query GetClockingReportDetail($id: Int!) {
    result: getClockingReportDetail(id: $id) {
      clockingReportID
      clockingPointID
      tag
      datetime
      remark
      status
      securityAgencyID
      clockingPointName
      siteProfileID
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listClockingReportDetail = /* GraphQL */ `
  query ListClockingReportDetail(
    $filter: ListClockingReportDetailFilterInput
    $pagination: PaginationInput
  ) {
    result: listClockingReportDetail(filter: $filter, pagination: $pagination) {
      result {
        clockingReportID
        clockingPointID
        tag
        datetime
        remark
        status
        securityAgencyID
        clockingPointName
        siteProfileID
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: Int!) {
    result: getContract(id: $id) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      startDatetime
      endDatetime
      status
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listContract = /* GraphQL */ `
  query ListContract(
    $filter: ListContractFilterInput
    $pagination: PaginationInput
  ) {
    result: listContract(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        clientProfileID
        startDatetime
        endDatetime
        status
        note
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getContractFile = /* GraphQL */ `
  query GetContractFile($id: Int!) {
    result: getContractFile(id: $id) {
      id
      contractID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listContractFile = /* GraphQL */ `
  query ListContractFile(
    $filter: ListContractFileFilterInput
    $pagination: PaginationInput
  ) {
    result: listContractFile(filter: $filter, pagination: $pagination) {
      result {
        id
        contractID
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getContractIndicator = /* GraphQL */ `
  query GetContractIndicator($id: Int!) {
    result: getContractIndicator(id: $id) {
      id
      contractID
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listContractIndicator = /* GraphQL */ `
  query ListContractIndicator(
    $filter: ListContractIndicatorFilterInput
    $pagination: PaginationInput
  ) {
    result: listContractIndicator(filter: $filter, pagination: $pagination) {
      result {
        id
        contractID
        title
        description
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getContractMeeting = /* GraphQL */ `
  query GetContractMeeting($id: Int!) {
    result: getContractMeeting(id: $id) {
      id
      contractID
      startDatetime
      endDatetime
      type
      venue
      note
      actionPlan
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listContractMeeting = /* GraphQL */ `
  query ListContractMeeting(
    $filter: ListContractMeetingFilterInput
    $pagination: PaginationInput
  ) {
    result: listContractMeeting(filter: $filter, pagination: $pagination) {
      result {
        id
        contractID
        startDatetime
        endDatetime
        type
        venue
        note
        actionPlan
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getDeployment = /* GraphQL */ `
  query GetDeployment($id: Int!) {
    result: getDeployment(id: $id) {
      id
      id2
      date
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      workHistoryID
      startDatetime
      endDatetime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDeployment = /* GraphQL */ `
  query ListDeployment(
    $filter: ListDeploymentFilterInput
    $pagination: PaginationInput
  ) {
    result: listDeployment(filter: $filter, pagination: $pagination) {
      result {
        id
        id2
        date
        securityAgencyID
        securitySiteID
        siteProfileID
        accountID
        staffProfileID
        workHistoryID
        startDatetime
        endDatetime
        staffName
        workHistoryDesignation
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getDeploymentTemplate = /* GraphQL */ `
  query GetDeploymentTemplate($id: Int!) {
    result: getDeploymentTemplate(id: $id) {
      id
      id2
      securityAgencyID
      title
      description
      lastUpdatedAccountID
      lastUpdatedStaffProfileID
      lastUpdatedStaffProfileName
      lastUpdatedStaffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDeploymentTemplate = /* GraphQL */ `
  query ListDeploymentTemplate(
    $filter: ListDeploymentTemplateFilterInput
    $pagination: PaginationInput
  ) {
    result: listDeploymentTemplate(filter: $filter, pagination: $pagination) {
      result {
        id
        id2
        securityAgencyID
        title
        description
        lastUpdatedAccountID
        lastUpdatedStaffProfileID
        lastUpdatedStaffProfileName
        lastUpdatedStaffProfileDesignation
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getDeploymentTemplateDetail = /* GraphQL */ `
  query GetDeploymentTemplateDetail($id: Int!) {
    result: getDeploymentTemplateDetail(id: $id) {
      id
      id2
      deploymentTemplateID
      securityAgencyID
      securitySiteID
      siteProfileID
      accountID
      staffProfileID
      startTime
      endTime
      staffName
      workHistoryDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listDeploymentTemplateDetail = /* GraphQL */ `
  query ListDeploymentTemplateDetail(
    $filter: ListDeploymentTemplateDetailFilterInput
    $pagination: PaginationInput
  ) {
    result: listDeploymentTemplateDetail(filter: $filter, pagination: $pagination) {
      result {
        id
        id2
        deploymentTemplateID
        securityAgencyID
        securitySiteID
        siteProfileID
        accountID
        staffProfileID
        startTime
        endTime
        staffName
        workHistoryDesignation
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getIncidentType = /* GraphQL */ `
  query GetIncidentType($id: Int!) {
    result: getIncidentType(id: $id) {
      id
      name
      deleted
    }
  }
`;
export const listIncidentType = /* GraphQL */ `
  query ListIncidentType(
    $filter: ListIncidentTypeFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentType(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        deleted
      }
    }
  }
`;
export const getIncidentReport = /* GraphQL */ `
  query GetIncidentReport($id: Int!) {
    result: getIncidentReport(id: $id) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listIncidentReport = /* GraphQL */ `
  query ListIncidentReport(
    $filter: ListIncidentReportFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentReport(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        uid
        submittedBy
        submittedByStaffProfileID
        submittedByDesignation
        submittedTimestamp
        submittedByStaffName
        closedBy
        closedByStaffProfileID
        closedByDesignation
        closedTimestamp
        closedByStaffName
        revision
        status
        statusHistory
        incidentDetailID
        incidentDetailAccountID
        incidentDetailStaffProfileID
        incidentDetailStaffProfileName
        incidentDetailStaffProfileDesignation
        type
        title
        location
        incidentStart
        incidentEnd
        personInvolved
        description
        action
        remarks
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getIncidentDetail = /* GraphQL */ `
  query GetIncidentDetail($id: Int!) {
    result: getIncidentDetail(id: $id) {
      id
      incidentReportID
      accountID
      staffProfileID
      designation
      staffProfileName
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listIncidentDetail = /* GraphQL */ `
  query ListIncidentDetail(
    $filter: ListIncidentDetailFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentDetail(filter: $filter, pagination: $pagination) {
      result {
        id
        incidentReportID
        accountID
        staffProfileID
        designation
        staffProfileName
        type
        title
        location
        incidentStart
        incidentEnd
        personInvolved
        description
        action
        remarks
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getIncidentFile = /* GraphQL */ `
  query GetIncidentFile($id: Int!) {
    result: getIncidentFile(id: $id) {
      id
      incidentReportID
      incidentDetailID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listIncidentFile = /* GraphQL */ `
  query ListIncidentFile(
    $filter: ListIncidentFileFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentFile(filter: $filter, pagination: $pagination) {
      result {
        id
        incidentReportID
        incidentDetailID
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getIncidentComment = /* GraphQL */ `
  query GetIncidentComment($id: Int!) {
    result: getIncidentComment(id: $id) {
      id
      incidentReportID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listIncidentComment = /* GraphQL */ `
  query ListIncidentComment(
    $filter: ListIncidentCommentFilterInput
    $pagination: PaginationInput
  ) {
    result: listIncidentComment(filter: $filter, pagination: $pagination) {
      result {
        id
        incidentReportID
        accountID
        staffProfileID
        staffProfileName
        comment
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getNationality = /* GraphQL */ `
  query GetNationality($id: Int!) {
    result: getNationality(id: $id) {
      country
      name
      nationality
      alpha2
      alpha3
      numeric
      subdivision
      independent
      order
      deleted
    }
  }
`;
export const listNationality = /* GraphQL */ `
  query ListNationality(
    $filter: ListNationalityFilterInput
    $pagination: PaginationInput
  ) {
    result: listNationality(filter: $filter, pagination: $pagination) {
      result {
        country
        name
        nationality
        alpha2
        alpha3
        numeric
        subdivision
        independent
        order
        deleted
      }
    }
  }
`;
export const getManagementAgency = /* GraphQL */ `
  query GetManagementAgency($id: Int!) {
    result: getManagementAgency(id: $id) {
      id
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listManagementAgency = /* GraphQL */ `
  query ListManagementAgency(
    $filter: ListManagementAgencyFilterInput
    $pagination: PaginationInput
  ) {
    result: listManagementAgency(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        address
        unitNo
        postal
        permission
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getOccurrence = /* GraphQL */ `
  query GetOccurrence($id: Int!) {
    result: getOccurrence(id: $id) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      reportedByAccountID
      reportedByStaffProfileID
      sn
      date
      datetime
      title
      description
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listOccurrence = /* GraphQL */ `
  query ListOccurrence(
    $filter: ListOccurrenceFilterInput
    $pagination: PaginationInput
  ) {
    result: listOccurrence(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        siteHistoryID
        reportedByAccountID
        reportedByStaffProfileID
        sn
        date
        datetime
        title
        description
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPaymentPlan = /* GraphQL */ `
  query GetPaymentPlan($id: Int!) {
    result: getPaymentPlan(id: $id) {
      id
      uid
      name
      description
      amount
      effectFrom
      effectTo
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPaymentPlan = /* GraphQL */ `
  query ListPaymentPlan(
    $filter: ListPaymentPlanFilterInput
    $pagination: PaginationInput
  ) {
    result: listPaymentPlan(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        name
        description
        amount
        effectFrom
        effectTo
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPLRDGrade = /* GraphQL */ `
  query GetPLRDGrade($id: Int!) {
    result: getPLRDGrade(id: $id) {
      id
      name
      code
      prerequisite
      yearsOfExperience
      mandatoryReq
      optionalReq
      deleted
    }
  }
`;
export const listPLRDGrade = /* GraphQL */ `
  query ListPLRDGrade(
    $filter: ListPLRDGradeFilterInput
    $pagination: PaginationInput
  ) {
    result: listPLRDGrade(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        code
        prerequisite
        yearsOfExperience
        mandatoryReq
        optionalReq
        deleted
      }
    }
  }
`;
export const getPWMTrainingModule = /* GraphQL */ `
  query GetPWMTrainingModule($id: Int!) {
    result: getPWMTrainingModule(id: $id) {
      id
      uid
      PLRDGradeID
      trainingModuleID
      mandatory
      from
      to
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPWMTrainingModule = /* GraphQL */ `
  query ListPWMTrainingModule(
    $filter: ListPWMTrainingModuleFilterInput
    $pagination: PaginationInput
  ) {
    result: listPWMTrainingModule(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        PLRDGradeID
        trainingModuleID
        mandatory
        from
        to
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getRace = /* GraphQL */ `
  query GetRace($id: Int!) {
    result: getRace(id: $id) {
      id
      name
      deleted
    }
  }
`;
export const listRace = /* GraphQL */ `
  query ListRace($filter: ListRaceFilterInput, $pagination: PaginationInput) {
    result: listRace(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        deleted
      }
    }
  }
`;
export const getReligion = /* GraphQL */ `
  query GetReligion($id: Int!) {
    result: getReligion(id: $id) {
      id
      name
      deleted
    }
  }
`;
export const listReligion = /* GraphQL */ `
  query ListReligion(
    $filter: ListReligionFilterInput
    $pagination: PaginationInput
  ) {
    result: listReligion(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        deleted
      }
    }
  }
`;
export const getSecuritySite = /* GraphQL */ `
  query GetSecuritySite($id: Int!) {
    result: getSecuritySite(id: $id) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSecuritySite = /* GraphQL */ `
  query ListSecuritySite(
    $filter: ListSecuritySiteFilterInput
    $pagination: PaginationInput
  ) {
    result: listSecuritySite(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        mcstNo
        uen
        address
        unitNo
        postal
        tier
        strataLot
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSecuritySiteReport = /* GraphQL */ `
  query GetSecuritySiteReport($id: Int!) {
    result: getSecuritySiteReport(id: $id) {
      id
      name
      mcstNo
      uen
      address
      unitNo
      postal
      type
      status
      securitySiteID
      reportedBy
      acceptedBy
      acceptedTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSecuritySiteReport = /* GraphQL */ `
  query ListSecuritySiteReport(
    $filter: ListSecuritySiteReportFilterInput
    $pagination: PaginationInput
  ) {
    result: listSecuritySiteReport(filter: $filter, pagination: $pagination) {
      result {
        id
        name
        mcstNo
        uen
        address
        unitNo
        postal
        type
        status
        securitySiteID
        reportedBy
        acceptedBy
        acceptedTimestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSecurityAgency = /* GraphQL */ `
  query GetSecurityAgency($id: Int!) {
    result: getSecurityAgency(id: $id) {
      id
      uid
      name
      address
      unitNo
      postal
      permission
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSecurityAgency = /* GraphQL */ `
  query ListSecurityAgency(
    $filter: ListSecurityAgencyFilterInput
    $pagination: PaginationInput
  ) {
    result: listSecurityAgency(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        name
        address
        unitNo
        postal
        permission
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSiteProfile = /* GraphQL */ `
  query GetSiteProfile($id: Int!) {
    result: getSiteProfile(id: $id) {
      id
      securitySiteID
      securityAgencyID
      managementAgencyID
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      status
      phoneNo
      siteHistoryID
      siteHistoryStart
      siteHistoryEnd
      siteHistoryStatus
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      clockingPointID
      clockingPointName
      clockingPointTag
      note
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSiteProfile = /* GraphQL */ `
  query ListSiteProfile(
    $filter: ListSiteProfileFilterInput
    $pagination: PaginationInput
  ) {
    result: listSiteProfile(filter: $filter, pagination: $pagination) {
      result {
        id
        securitySiteID
        securityAgencyID
        managementAgencyID
        name
        mcstNo
        uen
        address
        unitNo
        postal
        tier
        strataLot
        status
        phoneNo
        siteHistoryID
        siteHistoryStart
        siteHistoryEnd
        siteHistoryStatus
        clientProfileID
        clientProfilePermission
        clientProfileStatus
        clientProfileName
        clientProfilePhoneNo
        clientProfileEmail
        clockingPointID
        clockingPointName
        clockingPointTag
        note
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSiteHistory = /* GraphQL */ `
  query GetSiteHistory($id: Int!) {
    result: getSiteHistory(id: $id) {
      id
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      siteProfileID
      siteProfileName
      siteProfileMCSTNo
      siteProfileAddress
      siteProfileUnitNo
      siteProfilePostal
      siteProfileTier
      siteProfileStrataLot
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      start
      end
      status
      clockingPointID
      clockingPointName
      clockingPointTag
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSiteHistory = /* GraphQL */ `
  query ListSiteHistory(
    $filter: ListSiteHistoryFilterInput
    $pagination: PaginationInput
    $groupBy: String
  ) {
    result: listSiteHistory(
      filter: $filter
      pagination: $pagination
      groupBy: $groupBy
    ) {
      result {
        id
        securitySiteID
        securitySiteName
        securitySiteMCSTNo
        securitySiteAddress
        securitySiteUnitNo
        securitySitePostal
        securityAgencyID
        securityAgencyName
        securityAgencyAddress
        securityAgencyUnitNo
        securityAgencyPostal
        managementAgencyID
        managementAgencyName
        managementAgencyAddress
        managementAgencyUnitNo
        managementAgencyPostal
        siteProfileID
        siteProfileName
        siteProfileMCSTNo
        siteProfileAddress
        siteProfileUnitNo
        siteProfilePostal
        siteProfileTier
        siteProfileStrataLot
        clientProfileID
        clientProfilePermission
        clientProfileStatus
        clientProfileName
        clientProfilePhoneNo
        clientProfileEmail
        start
        end
        status
        clockingPointID
        clockingPointName
        clockingPointTag
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getSOPVersion = /* GraphQL */ `
  query GetSOPVersion($id: Int!) {
    result: getSOPVersion(id: $id) {
      id
      standardOperatingProcedureID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listSOPVersion = /* GraphQL */ `
  query ListSOPVersion(
    $filter: ListSOPVersionFilterInput
    $pagination: PaginationInput
  ) {
    result: listSOPVersion(filter: $filter, pagination: $pagination) {
      result {
        id
        standardOperatingProcedureID
        versionCode
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getStandardOperatingProcedure = /* GraphQL */ `
  query GetStandardOperatingProcedure($id: Int!) {
    result: getStandardOperatingProcedure(id: $id) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      siteHistoryID
      title
      description
      SOPVersionID
      versionCode
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listStandardOperatingProcedure = /* GraphQL */ `
  query ListStandardOperatingProcedure(
    $filter: ListStandardOperatingProcedureFilterInput
    $pagination: PaginationInput
  ) {
    result: listStandardOperatingProcedure(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        siteHistoryID
        title
        description
        SOPVersionID
        versionCode
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listStandardOperatingProcedureBySite = /* GraphQL */ `
  query ListStandardOperatingProcedureBySite(
    $filter: ListStandardOperatingProcedureFilterInput
    $pagination: PaginationInput
  ) {
    result: listStandardOperatingProcedureBySite(
      filter: $filter
      pagination: $pagination
    ) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        siteHistoryID
        title
        description
        SOPVersionID
        versionCode
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getStaffProfile = /* GraphQL */ `
  query GetStaffProfile($id: Int!) {
    result: getStaffProfile(id: $id) {
      id
      accountID
      securityAgencyID
      managementAgencyID
      name
      staffNo
      cognitoGroup
      designation
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      permission
      status
      workHistoryID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      dob
      gender
      phoneNo
      email
      preferred_username
      last4ID
      plrdGradeID
      emPassTag
      plrdName
      plrdCode
      emStart
      emEnd
      emStatus
      emType
      plrdDateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listStaffProfile = /* GraphQL */ `
  query ListStaffProfile(
    $filter: ListStaffProfileFilterInput
    $pagination: PaginationInput
  ) {
    result: listStaffProfile(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        securityAgencyID
        managementAgencyID
        name
        staffNo
        cognitoGroup
        designation
        profilePictureKey
        profilePictureBucket
        profilePictureRegion
        citizenship
        prFromDate
        nationality
        race
        religion
        maritalStatus
        emPassNo
        permission
        status
        workHistoryID
        securityAgencyName
        securityAgencyAddress
        securityAgencyUnitNo
        securityAgencyPostal
        managementAgencyName
        managementAgencyAddress
        managementAgencyUnitNo
        managementAgencyPostal
        dob
        gender
        phoneNo
        email
        preferred_username
        last4ID
        plrdGradeID
        emPassTag
        plrdName
        plrdCode
        emStart
        emEnd
        emStatus
        emType
        plrdDateIssued
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listStaffProfileByStaffTag = /* GraphQL */ `
  query ListStaffProfileByStaffTag(
    $filter: ListStaffProfileFilterInput
    $pagination: PaginationInput
    $staffTag: String!
  ) {
    result: listStaffProfileByStaffTag(
      filter: $filter
      pagination: $pagination
      staffTag: $staffTag
    ) {
      result {
        id
        accountID
        securityAgencyID
        managementAgencyID
        name
        staffNo
        cognitoGroup
        designation
        profilePictureKey
        profilePictureBucket
        profilePictureRegion
        citizenship
        prFromDate
        nationality
        race
        religion
        maritalStatus
        emPassNo
        permission
        status
        workHistoryID
        securityAgencyName
        securityAgencyAddress
        securityAgencyUnitNo
        securityAgencyPostal
        managementAgencyName
        managementAgencyAddress
        managementAgencyUnitNo
        managementAgencyPostal
        dob
        gender
        phoneNo
        email
        preferred_username
        last4ID
        plrdGradeID
        emPassTag
        plrdName
        plrdCode
        emStart
        emEnd
        emStatus
        emType
        plrdDateIssued
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getStaffTrainingModule = /* GraphQL */ `
  query GetStaffTrainingModule($id: Int!) {
    result: getStaffTrainingModule(id: $id) {
      id
      accountID
      staffProfileID
      securityAgencyID
      trainingModuleID
      title
      trainingStart
      trainingEnd
      learningHours
      expiry
      uid
      code
      module
      level
      trainingModuleTitle
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listStaffTrainingModule = /* GraphQL */ `
  query ListStaffTrainingModule(
    $filter: ListStaffTrainingModuleFilterInput
    $pagination: PaginationInput
  ) {
    result: listStaffTrainingModule(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        staffProfileID
        securityAgencyID
        trainingModuleID
        title
        trainingStart
        trainingEnd
        learningHours
        expiry
        uid
        code
        module
        level
        trainingModuleTitle
        duration
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: Int!) {
    result: getTicket(id: $id) {
      id
      uid
      securityAgencyID
      securitySiteID
      siteProfileID
      clientProfileID
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      status
      category
      title
      description
      priority
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listTicket = /* GraphQL */ `
  query ListTicket(
    $filter: ListTicketFilterInput
    $pagination: PaginationInput
  ) {
    result: listTicket(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        securityAgencyID
        securitySiteID
        siteProfileID
        clientProfileID
        submittedBy
        submittedByStaffProfileID
        submittedByDesignation
        submittedTimestamp
        submittedByStaffName
        closedBy
        closedByStaffProfileID
        closedByDesignation
        closedTimestamp
        closedByStaffName
        status
        category
        title
        description
        priority
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listTicketOverviewChart = /* GraphQL */ `
  query ListTicketOverviewChart(
    $filter: ListTicketFilterInput
    $pagination: PaginationInput
    $groupByStatus: Int
    $groupByCategory: Int
    $groupByPriority: Int
    $groupBySiteProfileID: Int
    $groupByClientProfileID: Int
  ) {
    result: listTicketOverviewChart(
      filter: $filter
      pagination: $pagination
      groupByStatus: $groupByStatus
      groupByCategory: $groupByCategory
      groupByPriority: $groupByPriority
      groupBySiteProfileID: $groupBySiteProfileID
      groupByClientProfileID: $groupByClientProfileID
    ) {
      status
      category
      priority
      siteProfileID
      clientProfileID
      total
    }
  }
`;
export const getTicketComment = /* GraphQL */ `
  query GetTicketComment($id: Int!) {
    result: getTicketComment(id: $id) {
      id
      ticketID
      accountID
      staffProfileID
      staffProfileName
      comment
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listTicketComment = /* GraphQL */ `
  query ListTicketComment(
    $filter: ListTicketCommentFilterInput
    $pagination: PaginationInput
  ) {
    result: listTicketComment(filter: $filter, pagination: $pagination) {
      result {
        id
        ticketID
        accountID
        staffProfileID
        staffProfileName
        comment
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getTicketFile = /* GraphQL */ `
  query GetTicketFile($id: Int!) {
    result: getTicketFile(id: $id) {
      id
      ticketID
      key
      bucket
      region
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listTicketFile = /* GraphQL */ `
  query ListTicketFile(
    $filter: ListTicketFileFilterInput
    $pagination: PaginationInput
  ) {
    result: listTicketFile(filter: $filter, pagination: $pagination) {
      result {
        id
        ticketID
        key
        bucket
        region
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getTrainingModule = /* GraphQL */ `
  query GetTrainingModule($id: Int!) {
    result: getTrainingModule(id: $id) {
      id
      uid
      code
      code2
      module
      level
      title
      duration
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listTrainingModule = /* GraphQL */ `
  query ListTrainingModule(
    $filter: ListTrainingModuleFilterInput
    $pagination: PaginationInput
  ) {
    result: listTrainingModule(filter: $filter, pagination: $pagination) {
      result {
        id
        uid
        code
        code2
        module
        level
        title
        duration
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: Int!) {
    result: getUser(id: $id) {
      id
      accountID
      name
      dob
      gender
      phoneNo
      email
      profilePictureKey
      profilePictureBucket
      profilePictureRegion
      status
      last4ID
      citizenship
      prFromDate
      nationality
      race
      religion
      maritalStatus
      emPassNo
      emPassTag
      plrdGradeID
      plrdName
      plrdCode
      notificationToken
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listUser = /* GraphQL */ `
  query ListUser($filter: ListUserFilterInput, $pagination: PaginationInput) {
    result: listUser(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        name
        dob
        gender
        phoneNo
        email
        profilePictureKey
        profilePictureBucket
        profilePictureRegion
        status
        last4ID
        citizenship
        prFromDate
        nationality
        race
        religion
        maritalStatus
        emPassNo
        emPassTag
        plrdGradeID
        plrdName
        plrdCode
        notificationToken
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getWorkHistory = /* GraphQL */ `
  query GetWorkHistory($id: Int!) {
    result: getWorkHistory(id: $id) {
      id
      accountID
      staffProfileID
      siteHistoryID
      securitySiteID
      securitySiteName
      securitySiteMCSTNo
      securitySiteAddress
      securitySiteUnitNo
      securitySitePostal
      securityAgencyID
      securityAgencyName
      securityAgencyAddress
      securityAgencyUnitNo
      securityAgencyPostal
      managementAgencyID
      managementAgencyName
      managementAgencyAddress
      managementAgencyUnitNo
      managementAgencyPostal
      designation
      emStart
      emEnd
      emStatus
      emType
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listWorkHistory = /* GraphQL */ `
  query ListWorkHistory(
    $filter: ListWorkHistoryFilterInput
    $pagination: PaginationInput
  ) {
    result: listWorkHistory(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        staffProfileID
        siteHistoryID
        securitySiteID
        securitySiteName
        securitySiteMCSTNo
        securitySiteAddress
        securitySiteUnitNo
        securitySitePostal
        securityAgencyID
        securityAgencyName
        securityAgencyAddress
        securityAgencyUnitNo
        securityAgencyPostal
        managementAgencyID
        managementAgencyName
        managementAgencyAddress
        managementAgencyUnitNo
        managementAgencyPostal
        designation
        emStart
        emEnd
        emStatus
        emType
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPLRDHistory = /* GraphQL */ `
  query GetPLRDHistory($id: Int!) {
    result: getPLRDHistory(id: $id) {
      id
      accountID
      plrdGradeID
      plrdName
      plrdCode
      dateIssued
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPLRDHistory = /* GraphQL */ `
  query ListPLRDHistory(
    $filter: ListPLRDHistoryFilterInput
    $pagination: PaginationInput
  ) {
    result: listPLRDHistory(filter: $filter, pagination: $pagination) {
      result {
        id
        accountID
        plrdGradeID
        plrdName
        plrdCode
        dateIssued
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getPurposeOfVisit = /* GraphQL */ `
  query GetPurposeOfVisit($id: Int!) {
    result: getPurposeOfVisit(id: $id) {
      id
      securityAgencyID
      managementAgencyID
      name
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listPurposeOfVisit = /* GraphQL */ `
  query ListPurposeOfVisit(
    $filter: ListPurposeOfVisitFilterInput
    $pagination: PaginationInput
  ) {
    result: listPurposeOfVisit(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        managementAgencyID
        name
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const getVisitorRecord = /* GraphQL */ `
  query GetVisitorRecord($id: Int!) {
    result: getVisitorRecord(id: $id) {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      type
      name
      phoneNo
      purpose
      block
      unit
      vehicleNo
      remarks
      inTimestamp
      outTimestamp
      createdOn
      updatedOn
      deleted
      deletedOn
    }
  }
`;
export const listVisitorRecord = /* GraphQL */ `
  query ListVisitorRecord(
    $filter: ListVisitorRecordFilterInput
    $pagination: PaginationInput
  ) {
    result: listVisitorRecord(filter: $filter, pagination: $pagination) {
      result {
        id
        securityAgencyID
        securitySiteID
        siteProfileID
        type
        name
        phoneNo
        purpose
        block
        unit
        vehicleNo
        remarks
        inTimestamp
        outTimestamp
        createdOn
        updatedOn
        deleted
        deletedOn
      }
      count
    }
  }
`;
export const listCognitoUser = /* GraphQL */ `
  query ListCognitoUser(
    $username: String
    $sub: String
    $filter: String
    $limit: Int
    $pagination: String
  ) {
    result: listCognitoUser(
      username: $username
      sub: $sub
      filter: $filter
      limit: $limit
      pagination: $pagination
    )
  }
`;
export const searchStaffProfile = /* GraphQL */ `
  query SearchStaffProfile(
    $securityAgencyID: Int
    $managementAgencyID: Int
    $accountID: String
    $nric: String
    $dob: String
    $username: String
    $phoneNo: String
  ) {
    result: searchStaffProfile(
      securityAgencyID: $securityAgencyID
      managementAgencyID: $managementAgencyID
      accountID: $accountID
      nric: $nric
      dob: $dob
      username: $username
      phoneNo: $phoneNo
    )
  }
`;
export const searchClientUserProfile = /* GraphQL */ `
  query SearchClientUserProfile(
    $securityAgencyID: Int
    $accountID: String
    $nric: String
    $dob: String
    $username: String
    $phoneNo: String
  ) {
    result: searchClientUserProfile(
      securityAgencyID: $securityAgencyID
      accountID: $accountID
      nric: $nric
      dob: $dob
      username: $username
      phoneNo: $phoneNo
    )
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    result: getNotification(id: $id) {
      id
      accountID
      agencyID
      title
      message
      obj
      read
      type
      createdOn
      updatedOn
      deleted
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    result: listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        agencyID
        title
        message
        obj
        read
        type
        createdOn
        updatedOn
        deleted
      }
      nextToken
    }
  }
`;
export const listNotificationByDate = /* GraphQL */ `
  query ListNotificationByDate(
    $accountID: String
    $createdOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    result: listNotificationByDate(
      accountID: $accountID
      createdOn: $createdOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        agencyID
        title
        message
        obj
        read
        type
        createdOn
        updatedOn
        deleted
      }
      nextToken
    }
  }
`;
