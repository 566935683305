const path = (page, payload) => {
    let pathTo = "/"
    switch (page) {
        case "login":
            pathTo = "/login"
            break
        case 'attendanceOnDuty':
            pathTo = '/attendance/onduty';
            break;
        case 'attendanceRecords':
            pathTo = '/attendance/records';
            break;
        case "patrolRecordsProfile":
            pathTo = "/patrol/records/" + payload[0]
            break
        case "patrolRecords":
            pathTo = "/patrol/records"
            break
        case "preferences":
            pathTo = "/settings/preferences"
            break
        case "deployment":
            pathTo = "/deployment"
            break
        case "emailChange":
            pathTo = "/settings/email/change"
            break
        case "emailVerify":
            pathTo = "/settings/email/verify"
            break
        case 'incidentReportCreate':
            pathTo = '/incident/create';
            break;
        case 'incidentDraftReportEdit':
            pathTo = `/incident/draft/${payload[0]}`;
            break;
        case 'incidentDraft':
            pathTo = '/incident/draft';
            break;
        case 'incidentReportEdit':
            pathTo = `/incident/${payload[0]}/edit`;
            break;
        case 'incidentReport':
            pathTo = `/incident/${payload[0]}`;
            break;
        case 'incident':
            pathTo = '/incident';
            break;
        case "mobileChange":
            pathTo = "/settings/mobile/change"
            break
        case "mobileVerify":
            pathTo = "/settings/mobile/verify"
            break
        case 'occurrence':
            pathTo = '/occurrence';
            break;
        case 'occurrenceCreate':
            pathTo = '/occurrence/create';
            break;
        case "passwordChange":
            pathTo = "/settings/password/change"
            break
        case "siteSelection":
            pathTo = "/site/selection"
            break
        case "settings":
            pathTo = "/settings"
            break
        case "home":
            pathTo = "/"
            break
        default:
    }
    return pathTo
}

export default path