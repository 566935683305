import React, { createContext, useContext, useEffect, useReducer } from "react"
import { notification } from "antd"
import { useHistory } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"
import API from "@aws-amplify/api"
import { CombineGraphQL } from "../utils/combineGraphQL"

import { listSiteProfile, listCitizenship, listNationality, listIncidentType, listRace, listReligion, listPLRDGrade } from "../backend/graphql/queries"
import { DataContext } from "../contexts/DataContext"
import { checkLoginUser } from '../utils/checkLocalStorage'

export const AppContext = createContext()

const initialState = {
    validUserLogin: checkLoginUser(),
    cognitoUserClient: {},
    profileAgency: {},
    broken: false,
    backButton: false,
    backURL: "",
    pageTitle: "",
    pageSubtitle: "",
    fixedListLoaded: false,
    citizenshipList: [],
    nationalityList: [],
    incidentTypeList: [],
    raceList: [],
    religionList: [],
    plrdGradeList: [],
    designationList: [],
    emStatusList: [],
    emTypeList: [],
    purposeOfVisitList: [],
    siteList: [], // only for agency portal
    // clientList: [], // only for agency portal
    permissionList: [],

    validSiteSelection: false,
    siteProfile: {},
    agencyID: 1,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_BROKEN":
            return {
                ...state,
                broken: action.payload,
            }
        case "SET_BACKBUTTON":
            return {
                ...state,
                backButton: action.payload,
            }
        case "SET_BACKURL":
            return {
                ...state,
                backURL: action.payload,
            }
        case "SET_PAGETITLE":
            return {
                ...state,
                pageTitle: action.payload,
            }
        case "SET_PAGESUBTITLE":
            return {
                ...state,
                pageSubtitle: action.payload,
            }
        case "SET_FIXEDLIST":
            return {
                ...state,
                ...action.payload,
            }
        case "SET_VALIDUSERLOGIN":
            return {
                ...state,
                validUserLogin: action.payload,
            }
        case "SET_COGNITOUSERCLIENT":
            return {
                ...state,
                cognitoUserClient: action.payload,
            }
        case 'SET_COGNITOUSERAGENCY':
            return {
                ...state,
                cognitoUserAgency: action.payload,
            }
        case "SET_PROFILEAGENCY":
            return {
                ...state,
                profileAgency: action.payload,
            }
        case "SET_VALIDSITESELECTION":
            return {
                ...state,
                validSiteSelection: action.payload,
            }
        case "SET_SITEPROFILE":
            return {
                ...state,
                siteProfile: action.payload,
            }
        default:
            break;
    }
}

const AppContextProvider = (props) => {
    const { dataState } = useContext(DataContext)
    const [appState, dispatch] = useReducer(reducer, initialState)
    const history = useHistory()
    // let subscriptionOnUpdateClientProfile

    // const getCitizenshipList = () => {
    //     return dataState.citizenshipList
    // }

    // const getNationalityList = () => {
    //     return dataState.nationalityList
    // }

    // const getIncidentTypeList = () => {
    //     return dataState.incidentTypeList
    // }

    // const getRaceList = () => {
    //     return dataState.raceList
    // }

    // const getReligionList = () => {
    //     return dataState.religionList
    // }

    // const getplrdGradeList = () => {
    //     return dataState.plrdGradeList
    // }

    const getDesignationList = () => {
        return dataState.designationList
    }

    const getEmStatusList = () => {
        return dataState.emStatusList
    }

    const getEmTypeList = () => {
        return dataState.emTypeList
    }

    const getPurposeOfVisitList = () => {
        return dataState.purposeOfVisitList
    }

    // const getSiteList = () => {
    //     return dataState.siteList
    // }

    // const getClientList = () => {
    //     return dataState.clientList
    // }

    const getPermissionList = () => {
        return dataState.permissionList
    }

    const getFixedList = async () => {
        // console.log("getFixedList");
        try {

            let combineGraphQL = new CombineGraphQL()
            combineGraphQL.addQuery(listCitizenship, {})
            combineGraphQL.addQuery(listNationality, {})
            combineGraphQL.addQuery(listIncidentType, {})
            combineGraphQL.addQuery(listRace, {})
            combineGraphQL.addQuery(listReligion, {})
            combineGraphQL.addQuery(listPLRDGrade, {})
            // combineGraphQL.addQuery(listSiteHistory, {})

            let { query, variables } = combineGraphQL.getGraphQLQuery();
            // console.log(query, variables)

            const result = await API.graphql({
                query: query,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables,
                // authMode: "AWS_IAM",
                // query: listNationality,
            })
            // console.log("fixed list", result);

            dispatch({
                type: "SET_FIXEDLIST", payload: {
                    citizenshipList: result.data.result0.result,
                    nationalityList: result.data.result1.result,
                    incidentTypeList: result.data.result2.result,
                    raceList: result.data.result3.result,
                    religionList: result.data.result4.result,
                    plrdGradeList: result.data.result5.result,
                    designationList: getDesignationList(), // no table, additional ADMIN option
                    emStatusList: getEmStatusList(), // no table
                    emTypeList: getEmTypeList(), // no table
                    purposeOfVisitList: getPurposeOfVisitList(), // no table
                    permissionList: getPermissionList(),
                    // fixedListLoaded: true,
                    // siteList: result.data.listSiteHistory.result,
                    // clientList: result.data.listClientProfile.result,
                }
            })
        }
        catch (error) {
            console.log("error: ", error);
            if (error.message === "No current user") {
                notification.error({
                    message: "Token has expired, please re-login."
                })
                await Auth.signOut()
                actionLogout()
            }
        }
        finally { }
    }

    const getSiteList = async () => {
        try {
            const listSiteListDetails = {
                filter: {
                    securityAgencyID: { eq: appState.agencyID }
                },
                pagination: {
                    orderby: '`name` ASC',
                    agencyID: appState.agencyID
                }
            }
            const result = await API.graphql({
                query: listSiteProfile,
                variables: listSiteListDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            })
            // console.log("listSiteProfile", result);

            dispatch({
                type: "SET_FIXEDLIST", payload: {
                    siteList: result.data.result.result === null ? [] : result.data.result.result,
                    fixedListLoaded: true,
                }
            })
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { }
    }

    // const getClientAndSiteList = async () => {
    //     try {
    //         const listSiteListDetails = {
    //             filter: {
    //                 securityAgencyID: { eq: appState.profileAgency.securityAgencyID }
    //             },
    //             filter2: {
    //                 securityAgencyID: { eq: appState.profileAgency.securityAgencyID }
    //             },
    //             pagination: {
    //                 agencyID: appState.profileAgency.securityAgencyID
    //             }
    //         }
    //         const result = await API.graphql({
    //             query: listClientProfile,
    //             variables: listSiteListDetails,
    //             authMode: "AMAZON_COGNITO_USER_POOLS",
    //         })
    //         // console.log("listClientProfile", result);

    //         dispatch({
    //             type: "SET_FIXEDLIST", payload: {
    //                 // siteList: result.data.listSiteHistory.result === null ? [] : result.data.listSiteHistory.result,
    //                 // clientList: result.data.listClientProfile.result === null ? [] : result.data.listClientProfile.result,
    //                 siteList: result.data.result2.result === null ? [] : result.data.result2.result,
    //                 clientList: result.data.result.result === null ? [] : result.data.result.result,
    //                 fixedListLoaded: true,
    //             }
    //         })
    //     }
    //     catch (error) {
    //         console.log("error: ", error);
    //     }
    //     finally { }
    // }

    const getFromLocalStorage = async () => {
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_site"))
        const cognitoGroups = user.signInUserSession.idToken.payload["cognito:groups"]
        // console.log("cognitoGroups", cognitoGroups);
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("profiles", profiles);

        let platformPermission = false

        let profile = profiles.find(s => s.securityAgencyID === appState.agencyID);
        // console.log('profile', profile);

        if (profile && profile.staffProfileID > 0) {
            platformPermission = true;
        }

        if (platformPermission) {
            actionLogin(user);
            let siteProfile = localStorage.getItem("isap_siteprofile")
            // console.log('siteProfile appstate', siteProfile);
            if (siteProfile) {
                actionSetSite(JSON.parse(localStorage.getItem("isap_siteprofile")))
            }
        }
        else {
            try {
                await Auth.signOut();
            } catch (error) {
                console.log('error signing out: ', error);
            }
            notification.error({
                message: "Not a registered agency admin"
            })
        }
    }

    // const subscriptionOnUpdateClientProfileSetup = () => {
    //     try {
    //         subscriptionOnUpdateClientProfile = API.graphql({
    //             query: onUpdateClientProfile,
    //             authMode: "AMAZON_COGNITO_USER_POOLS",
    //             variables: { securityAgencyID: appState.profileAgency.securityAgencyID }
    //         }).subscribe({
    //             next: async ({ provider, value }) => {
    //                 console.log(provider);
    //                 console.log(value);
    //             }
    //         })
    //     }
    //     catch (error) {
    //         console.log("error", error);
    //     }
    // }

    // useEffect(() => {
    //     console.log("appState", appState);
    // }, [appState])

    useEffect(() => {
        if (localStorage.getItem("isap_cognitouser_site") !== null) {
            getFromLocalStorage()
        }

        return () => {
            // if (subscriptionOnUpdateClientProfile !== undefined) {
            //     subscriptionOnUpdateClientProfile.unsubscribe()
            // }
        }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.cognitoUserClient).length !== 0 && appState.fixedListLoaded === false) {
            getFixedList()
        }
    }, [appState.cognitoUserClient])

    useEffect(() => {
        // if (appState.fixedListLoaded === true) {
        if (appState.fixedListLoaded === false && appState.nationalityList.length !== 0) {
            getSiteList()
        }
        // }, [appState.fixedListLoaded])
    }, [appState.nationalityList])

    // Actions
    const actionLogin = (user) => {
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)

        let profile = profiles.find(s => s.securityAgencyID === appState.agencyID);

        localStorage.removeItem("isap_cognitouser_site")
        localStorage.setItem("isap_cognitouser_site", JSON.stringify(user))
        dispatch({ type: "SET_VALIDUSERLOGIN", payload: true })
        dispatch({ type: "SET_COGNITOUSERCLIENT", payload: user })
        dispatch({ type: "SET_PROFILEAGENCY", payload: profile })
    }

    const actionLogout = () => {
        dispatch({ type: "SET_VALIDUSERLOGIN", payload: false })
        dispatch({ type: "SET_COGNITOUSERCLIENT", payload: {} })
        dispatch({ type: "SET_PROFILEAGENCY", payload: {} })
        dispatch({ type: "SET_VALIDSITESELECTION", payload: false })
        dispatch({ type: "SET_SITEPROFILE", payload: {} })
        localStorage.removeItem("isap_cognitouser_site")
        localStorage.removeItem("isap_siteprofile")
    }

    const actionSetSite = (siteProfile) => {
        // console.log('actionSetSite', siteProfile);
        siteProfile = {
            siteProfileID: siteProfile.id,
            ...siteProfile
        }

        localStorage.setItem("isap_siteprofile", JSON.stringify(siteProfile))
        dispatch({ type: "SET_VALIDSITESELECTION", payload: true })
        dispatch({ type: "SET_SITEPROFILE", payload: siteProfile })
    }

    const actionSetBroken = (payload) => {
        dispatch({ type: "SET_BROKEN", payload: payload })
    }

    const actionSetPageTitle = (title, boolBackBtn, backURL) => {
        dispatch({ type: "SET_PAGETITLE", payload: title })
        if (boolBackBtn !== undefined)
            dispatch({ type: "SET_BACKBUTTON", payload: boolBackBtn })
        if (backURL !== undefined)
            dispatch({ type: "SET_BACKURL", payload: backURL })
    }

    return (
        <AppContext.Provider value={{ appState, actionLogin, actionLogout, actionSetBroken, actionSetPageTitle, actionSetSite }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider