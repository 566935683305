import React, { useContext, useEffect, useState } from "react"
import { Button, Checkbox, Form, Input, notification, Select, Spin, Tabs } from "antd"
import { UserOutlined, LockOutlined, EnvironmentOutlined } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import Auth from "@aws-amplify/auth"

import { AppContext } from '../contexts/AppContext'

import Logo from "../media/logo-inimicode-light-155x31.png"
import path from "../utils/pathSettings"

const SiteSelection = (props) => {
    const { appState, actionLogin, actionSetSite } = useContext(AppContext)
    const { from, toChange } = props.location.state || { from: "/" }
    const { Option } = Select
    const history = useHistory()
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email.",
        },
    }

    useEffect(() => {
        // console.log('siteSelection', toChange, appState.validUserLogin, appState.validSiteSelection, appState)
        if (!toChange) {
            if (appState.validUserLogin !== true) {
                history.push(path('login'))
            } else if (appState.validUserLogin && appState.validSiteSelection) {
                // history.push(path('home'))
                history.push(from)
            }
        }
        // if (appState.validUserLogin === true) {
        //     history.push(from)
        // }
        // if (localStorage.getItem("isap_remember_site") !== null) {
        //     form.setFieldsValue({
        //         username: localStorage.getItem("isap_remember_site"),
        //         remember: true,
        //     })
        // }
        // else {
        //     form.setFieldsValue({
        //         remember: false
        //     })
        // }
        return () => { }
    }, [])

    const onFinish = async (values) => {
        // console.log("onFinish", values);
        setLoading(true)

        actionSetSite(appState.siteList.find(s => s.id === values.siteID));
        history.push(path('home'))

        setLoading(false)
        // const timer = setTimeout(() => {
        //     setLoading(false)
        //     clearTimeout(timer)
        // }, 1000)
    }

    const handleCheckChange = (e) => {
        // console.log("handleCheckChange");
        if (form.getFieldValue("remember") === false) {
            localStorage.removeItem("isap_remember_site")
        }
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                    </div>
                    <div className="login-subheader">
                        Site Selection
                    </div>
                    <div className="login-content">
                        <Spin spinning={loading}>
                            <Form
                                form={form}
                                name="normal_login"
                                className="login-form"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    name="siteID"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    {/* <Input autoComplete="off" prefix={<EnvironmentOutlined className="site-form-item-icon" />} placeholder="Site name" /> */}
                                    <Select
                                        showSearch
                                        placeholder="Select Site"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {renderSiteListOptions(appState.siteList.filter(s => s.status === 'ACTIVE'))}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Confirm
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteSelection